import React, {Component} from 'react';
import "./ScreenContainer.scss";

export default class ScreenContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return <div key="ScreenContainer" className="ScreenContainer">
            {this.props.children}
        </div>
    }
}








