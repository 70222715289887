import React, {Component} from 'react';
import {navigate} from "@reach/router";
import EagleIcon from './EagleIcon';
import "./FindMyBrickScreen.scss";
import axios from 'axios';
import _ from 'lodash';

import {AppButton} from './Commons';
import LoadingIcon from "./LoadingIcon";
import Footer from "./Footer";
import PaginationList from "./PaginationList";

const StateData = _.keyBy([
    {name: "Alabama", code: "AL", category: "Southeast"},
    {name: "Alaska", code: "AK", category: "West"},
    {name: "Arizona", code: "AZ", category: "Southwest"},
    {name: "Arkansas", code: "AR", category: "Southeast"},
    {name: "California", code: "CA", category: "West"},
    {name: "Colorado", code: "CO", category: "West"},
    {name: "Connecticut", code: "CT", category: "Northeast"},
    {name: "Delaware", code: "DE", category: "Northeast"},
    {name: "District of Columbia", code: "DC", category: ""},
    {name: "Florida", code: "FL", category: "Southeast"},
    {name: "Georgia", code: "GA", category: "Southeast"},
    {name: "Hawaii", code: "HI", category: "West"},
    {name: "Idaho", code: "ID", category: "West"},
    {name: "Illinois", code: "IL", category: "Midwest"},
    {name: "Indiana", code: "IN", category: "Midwest"},
    {name: "Iowa", code: "IA", category: "West"},
    {name: "Kansas", code: "KS", category: "Midwest"},
    {name: "Kentucky", code: "KY", category: "Southeast"},
    {name: "Louisiana", code: "LA", category: "Southeast"},
    {name: "Maine", code: "ME", category: "Northeast"},
    {name: "Maryland", code: "MD", category: "Northeast"},
    {name: "Massachusetts", code: "MA", category: "Northeast"},
    {name: "Michigan", code: "MI", category: "Midwest"},
    {name: "Minnesota", code: "MN", category: "Midwest"},
    {name: "Mississippi", code: "MS", category: "Southeast"},
    {name: "Missouri", code: "MO", category: "Midwest"},
    {name: "Montana", code: "MT", category: "West"},
    {name: "Nebraska", code: "NE", category: "Midwest"},
    {name: "Nevada", code: "NV", category: "West"},
    {name: "New Hampshire", code: "NH", category: "Northeast"},
    {name: "New Jersey", code: "NJ", category: "Northeast"},
    {name: "New Mexico", code: "NM", category: "Southwest"},
    {name: "New York", code: "NY", category: "Northeast"},
    {name: "North Carolina", code: "NC", category: "Southeast"},
    {name: "North Dakota", code: "ND", category: "Midwest"},
    {name: "Ohio", code: "OH", category: "Midwest"},
    {name: "Oklahoma", code: "OK", category: "Southwest"},
    {name: "Oregon", code: "OR", category: "West"},
    {name: "Pennsylvania", code: "PA", category: "Northeast"},
    {name: "Rhode Island", code: "RI", category: "Northeast"},
    {name: "South Carolina", code: "SC", category: "Southeast"},
    {name: "South Dakota", code: "SD", category: "West"},
    {name: "Tennessee", code: "TN", category: "Southeast"},
    {name: "Texas", code: "TX", category: "Southwest"},
    {name: "Utah", code: "UT", category: "West"},
    {name: "Vermont", code: "VT", category: "Northeast"},
    {name: "Virginia", code: "VA", category: "Southeast"},
    {name: "Washington", code: "WA", category: "West"},
    {name: "West Virginia", code: "WV", category: "Southeast"},
    {name: "Wisconsin", code: "WI", category: "Midwest"},
    {name: "Wyoming", code: "WY", category: "West"},
    {name: "Armed Forces America", code: "AA", category: "Armed Forces"},
    {name: "Armed Forces Europe", code: "AE", category: "Armed Forces"},
    {name: "Armed Forces Pacific", code: "AP", category: "Armed Forces"},
    // {name: "US Virgin Islands", code: "VI", category: "US Territories"}
], x => x.code);

export default class FindMyBrickScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeOfSearch: this.props.path,
            previousQuery: '',
            isRefiningSearch: false,
            query: '',
            isSearching: false,
            isShowingResults: false,
            isShowingStateFilters: false,
            isShowingAreaFilters: false,
            isShowingAllResults: false,
            isEmptyResult: false,
            selectedBrick: null,
            results: [],
            allResults: [],
            groupByArea: [],
            groupByState: [],
            selectedState: null,
            currentPage: 0,
            pageSize: 7,
            title: "Find My Brick Search",
            queryParameter: "donor",
        };
        console.log("creation");
    }

    componentWillMount() {
        if(window.savedState) {
            this.setState(window.savedState);
            delete window.savedState;
        }
        console.log("componentWillMount");
    }

    componentWillReceiveProps(props) {

        console.log("componentWillReceiveProps");
    }

    componentWillUpdate(props) {
        console.log("componentWillUpdate");
    }

    shouldComponentUpdate() {
        console.log("shouldComponentUpdate");
        return true;
    }

    handleChange = (e) => {
        this.setState({query: e.target.value});
    };

    buildFinalQuery() {
        const {query, previousQuery, isRefiningSearch} = this.state;
        if (isRefiningSearch && isNaN(query)) {
            return previousQuery + " " + query;
        }
        return query;
    }

    async searchForDonors(id) {
        let url = "https://us-central1-ak-mapping-api.cloudfunctions.net/get_donor_by_id";
        const headers = {
            authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
        };
        const config = {
            url,
            headers,
            method: 'get',
            params: {
                id: id,
            }
        };
        const results = await axios(config);
        this.setState({
            selectedPerson: results.data.data,
        });
    }

    async searchForHonoree(id) {
        let url = "https://us-central1-ak-mapping-api.cloudfunctions.net/get_honoree_by_id";
        const headers = {
            authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
        };
        const config = {
            url,
            headers,
            method: 'get',
            params: {
                id: id,
            }
        };
        const results = await axios(config);
        this.setState({
            selectedPerson: results.data.data,
        });
    }

    async searchForBricks(finalQuery) {
        let url = "https://us-central1-ak-mapping-api.cloudfunctions.net/search_bricks";
        const headers = {
            authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
        };

        const brickNumberConfig = {
            url,
            headers,
            method: 'get',
            params: {
                brickNumber: finalQuery,
                page: 1
            }
        };

        const honorConfig = {
            url,
            headers,
            method: 'get',
            params: {
                honor: finalQuery,
                page: 1
            }
        };

        const brickNumberResults = await axios(brickNumberConfig);
        const honorResults = await axios(honorConfig);
        console.log({
            BrickNumber: brickNumberResults.data.items.length,
            DonorHonor: honorResults.data.items.length,
        });
        if (brickNumberResults.data.items.length > 0
            && brickNumberResults.data.items[0].brickNumber === finalQuery) {
            const selectedBrick = brickNumberResults.data.items[0].brickNumber;
            const savedState = this.state;
            savedState.isSearching = false;
            this.setState({
                results: brickNumberResults.data,
                selectedBrick,
                isPickingOrigin: true,
                savedState,
            });
        } else {
            this.setState({results: honorResults.data}, this.processQueryResults)
        }
    }

    async searchHonorees(finalQuery) {
        let url = "https://us-central1-ak-mapping-api.cloudfunctions.net/search_honorees";
        const headers = {
            authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
        };
        const config = {
            url,
            headers,
            method: 'get',
            params: {
                honoree: finalQuery,
            }
        };
        const results = await axios(config);
        this.setState({
            isSearching: false,
            selectedPerson: null,
            isShowPeopleResults: true,
            results: results.data.items,
            savedState: this.state,
        }, this.processPeopleResults);
    }

    async searchDonors(finalQuery) {
        let url = "https://us-central1-ak-mapping-api.cloudfunctions.net/search_donors";
        const headers = {
            authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
        };
        const config = {
            url,
            headers,
            method: 'get',
            params: {
                donor: finalQuery,
            }
        };
        const results = await axios(config);
        this.setState({
            isSearching: false,
            selectedPerson: null,
            isShowPeopleResults: true,
            results: results.data.items,
            savedState: this.state,
        }, this.processPeopleResults);
    }

    handleSearch = async () => {
        try {
            let finalQuery = this.buildFinalQuery();
            console.info("finalQuery: " + finalQuery);
            this.setState({
                isSearching: true,
            });
            const {typeOfSearch} = this.state;
            if (typeOfSearch === "donor-search") {
                this.searchDonors(finalQuery);
            } else if (typeOfSearch === "honoree-search") {
                this.searchHonorees(finalQuery);
            } else {
                this.searchForBricks(finalQuery);
            }
        } catch (error) {
            alert("There was an error performing the search. Please try again.");
        }
    };

    handleStateSelection = (stateCode) => () => {
        const {results, lastPage, currentPage} = this.prepareResultsByState(stateCode);
        this.setState({
            results,
            lastPage,
            currentPage,
            selectedState: stateCode,
            isShowingResults: true,
            isShowingStateFilters: false,
        });
    };

    prepareResultsByState(stateCode) {
        const {groupByState, pageSize} = this.state;
        const results = groupByState[stateCode];
        const lastPage = Math.ceil(results.length / pageSize);
        return {
            results,
            lastPage,
            currentPage: 0,
        }
    }

    prepareResultsByArea(selectedArea) {
        const {groupByArea, pageSize} = this.state;
        const results = groupByArea[selectedArea];
        const lastPage = Math.ceil(results.length / pageSize);
        return {
            results,
            lastPage,
            currentPage: 0,
        }
    }

    prepareResults() {
        const {pageSize, allResults} = this.state;
        const lastPage = Math.ceil(allResults.length / pageSize);
        return {
            results: allResults,
            lastPage,
            currentPage: 0,
        }
    }

    handleUnknownLocation = () => {
        const selectedArea = "Unknown-Location";
        const {results, lastPage, currentPage} = this.prepareResultsByArea(selectedArea);
        this.setState({
            selectedArea,
            results,
            lastPage,
            currentPage,
            isShowingResults: true,
            isShowingStateFilters: false,
            isShowingAreaFilters: false
        });
    };

    handleViewAllResults = () => {
        const {results, lastPage, currentPage} = this.prepareResults();
        this.setState({
            results,
            lastPage,
            currentPage,
            isShowingAllResults: true,
            isShowingStateFilters: false,
            isShowingAreaFilters: false
        });
    };

    handleRefineSearch = () => {
        const {query} = this.state;
        this.setState({
            // isRefiningSearch: true,
            previousQuery: query,
            query: query + " ",
            isShowPeopleResults: false,
            isShowingAllResults: false,
            isShowingStateFilters: false,
            isShowingAreaFilters: false
        });
    };

    handleAreaSelection = (areaCode) => () => {
        const {groupByArea} = this.state;
        const groupByState = _.groupBy(groupByArea[areaCode], x => x.donorState ? x.donorState : "Unknown-Location");
        this.setState({
            groupByState,
            selectedArea: areaCode,
            isShowingResults: false,
            isShowingStateFilters: true,
            isShowingAreaFilters: false,
        });
    };

    processPeopleResults() {
        const data = this.state.results;
        if (data.length === 0) {
            this.setState({
                isEmptyResult: true,
            });
        }
    }

    processQueryResults() {
        const data = this.state.results;
        const terms = _.map(this.buildFinalQuery().split(" "), x => x.toUpperCase());
        let filteredData = data.items;
        if (this.state.typeOfSearch === "find-my-brick-search") {
            filteredData = _.filter(filteredData, entry => {
                const allTermsIncluded = _.filter(terms, t => entry.honor.toUpperCase().indexOf(t) !== -1);
                return allTermsIncluded.length === terms.length;
            });
        }

        if (filteredData.length === 0) {
            this.setState({
                isEmptyResult: true,
            });
        } else if (filteredData.length === 1) {
            const selectedBrick = filteredData[0].brickNumber;
            this.setState({
                selectedBrick,
                isPickingOrigin: true,
                savedState: this.state,
            });
        } else {
            const groupByArea = _.groupBy(filteredData, x => {
                if (x.donorState) {
                    if (StateData.hasOwnProperty(x.donorState)) {
                        return StateData[x.donorState].category;
                    } else {
                        return "Unknown-Location";
                    }
                }
                return "Unknown-Location";
            });
            this.setState({
                isEmptyResult: false,
                allResults: filteredData,
                groupByArea,
                isSearching: false,
                isShowingAreaFilters: true,
            });
        }
    }

    renderQueryScreen() {
        let title = this.state.title;
        const {typeOfSearch, isRefiningSearch, query} = this.state;
        if (typeOfSearch === "donor-search") {
            title = "Donor Search";
        } else if (typeOfSearch === "honoree-search") {
            title = "Honoree Search";
        }
        const placeholder = isRefiningSearch ? "Refine your search" : "Type your search";
        return <div key={"query-screen"}
                    className={"flex-column"}>
            <h1>{title}</h1>
            <EagleIcon/>
            {/*{isRefiningSearch && <div className="previous_search">*/}
            {/*<strong>Previous Search</strong> {previousQuery}*/}
            {/*</div>}*/}
            <input placeholder={placeholder}
                   value={query}
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           this.handleSearch();
                       }
                   }}
                   onChange={this.handleChange}/>
            <AppButton title="Search"
                       action={this.handleSearch}/>
            <Footer/>
        </div>
    }

    renderLoadingScreen() {
        return <div key={"loading-screen"}>
            <LoadingIcon/>
        </div>;
    }

    renderStateFilters() {
        const {groupByState} = this.state;
        console.log(groupByState);
        return <div key="state-list"
                    className="selectionDialog">
            <h1>Select A State</h1>
            <div className="state-list">
                {_.map(groupByState, this.renderStateButton)}
            </div>
            <div className="specialButtonArea">
                <button className="specialButton"
                        onClick={this.handleViewAllResults}>View All Results
                </button>
                <button className="specialButton"
                        onClick={this.handleRefineSearch}>Refine Search
                </button>
            </div>
            <Footer backAction={() => {
                this.setState({
                    isSearching: false,
                    isShowingResults: false,
                    isShowingStateFilters: false,
                    isShowingAreaFilters: true,
                    isEmptyResult: false,
                });
            }}/>
        </div>;
    }

    renderAreaFilters() {
        const {groupByArea} = this.state;
        return <div key="area-list"
                    className="selectionDialog">
            <h1>Select A Region</h1>
            <div className="state-list">
                {_.map(groupByArea, this.renderAreaButton)}
            </div>
            <div className="specialButtonArea">
                <button className="specialButton"
                        onClick={this.handleViewAllResults}>View All Results
                </button>
                <button className="specialButton"
                        onClick={this.handleRefineSearch}>Refine Search
                </button>
            </div>
            <Footer backAction={() => {
                this.setState({
                    isSearching: false,
                    isShowingResults: false,
                    isShowingStateFilters: false,
                    isShowingAreaFilters: false,
                    isEmptyResult: false,
                    query: ''
                });
            }}/>
        </div>;
    }

    renderStateButton = (results, stateCode) => {
        let key = "NoState";
        let displayName = "State Unknown";
        if (StateData.hasOwnProperty(stateCode)) {
            key = stateCode;
            displayName = StateData[stateCode].name;
        }
        return <div key={key}
                    className="stateButton"
                    onClick={this.handleStateSelection(stateCode)}>
            <h2>{displayName} ({results.length})</h2>
        </div>
    };

    renderAreaButton = (results, areaCode) => {
        if (!areaCode) {
            return null;
        }
        let imagePath = "/images/regions/" + areaCode.toLowerCase().replace(" ", "-") + ".png";
        const count = results.length;
        if (areaCode === "Unknown-Location") {
            imagePath = "/images/regions/" + areaCode.toLowerCase().replace(" ", "-") + ".svg";
            return <div key="unknown-location"
                        className="areaButton"
                        onClick={this.handleUnknownLocation}>
                <img src={imagePath} alt=""/>
                <h2>Other ({count})</h2>
            </div>
        }
        return <div key={areaCode}
                    className="areaButton"
                    onClick={this.handleAreaSelection(areaCode)}>
            <img src={imagePath} alt=""/>
            <h2>{areaCode} ({count})</h2>
        </div>
    };

    renderPersonLink = (person, key) => {
        const {firstName, lastName, city, state, donor, honoree} = person;
        if (this.state.typeOfSearch === "honoree-search") {
            return <div key={key}
                      className="peopleLink"
                      onClick={() => {
                          this.setState({
                              selectedPerson: person,
                              savedState: this.state,
                          });
                      }}>
                <div className="peopleName">
                    <p>{honoree}</p>
                </div>
                <div className="peopleLocation">
                    <span>{city}, {state}</span>
                </div>
            </div>
        }

        return <div key={key}
                  className="peopleLink"
                  onClick={() => {
                      this.setState({
                          selectedPerson: person,
                          savedState: this.state,
                      });
                  }}>
            <div className="peopleName">
                <p>{firstName} {lastName}</p>
                <p>{donor}</p>
            </div>
            <div className="peopleLocation">
                <span>{city}, {state}</span>
            </div>
        </div>
    };

    renderBrickLink = (brick, key) => {
        const {
            brickNumber,
            inscriptionLine1,
            inscriptionLine2,
            inscriptionLine3,
            donor,
            donorCity,
            donorState
        } = brick;
        return <div key={key}
                  className="brinkLink"
                  onClick={() => {
                      this.setState({
                          selectedBrick: brickNumber,
                          isPickingOrigin: true,
                          savedState: this.state,
                      })
                  }}>
            <div className="brickNumber">
                No. {brickNumber}
            </div>
            <div className="brickDescription">
                <p>{inscriptionLine1}</p>
                <p>{inscriptionLine2}</p>
                <p>{inscriptionLine3}</p>
            </div>
            <div className="donor">
                {donor}<br/>
                {donorCity && <span>{donorCity}, {donorState}</span>}
            </div>
        </div>
    };

    renderBrickLink2 = (brick, key) => {
        const {
            brickNumber,
            description,
        } = brick;
        const lines = description.split("\n");
        return <div key={key}
                  className="brinkLink"
                  onClick={() => {
                      this.setState({
                          selectedBrick: brickNumber,
                          isPickingOrigin: true,
                          savedState: this.state,
                      })
                  }}>
            <div className="brickNumber">
                No. {brickNumber}
            </div>
            <div className="brickDescription">
                <p>{lines[0]}</p>
                <p>{lines[1]}</p>
                <p>{lines[2]}</p>
            </div>
        </div>
    };

    changePage = (increment) => () => {
        const {currentPage, lastPage} = this.state;
        const newPage = currentPage + increment;
        if (newPage < 0 || newPage > lastPage) {
            return;
        }
        this.setState({currentPage: newPage});
    };

    renderResults(fromAllResults) {
        const {results, lastPage, pageSize, currentPage} = this.state;
        const items = [];
        for (let i = (currentPage * pageSize); i < Math.min((currentPage + 1) * pageSize, results.length); i++) {
            items.push(results[i]);
        }
        const canGoBack = currentPage !== 0;
        const canGoForward = (currentPage + 1) !== lastPage;
        const pageDescription = `Page ${currentPage + 1} of ${lastPage}`
        return <div key={"results-screen"}>
            <div key="results"
                 className="brick-list">
                {_.map(items, this.renderBrickLink)}
            </div>
            <div className="specialButtonArea">
                <button className="specialButton"
                        onClick={this.handleRefineSearch}>Refine Search
                </button>
            </div>
            <div className="paginationPanel">
                <div className="button-container">
                    {canGoBack && <button onClick={this.changePage(-1)}>Previous</button>}
                </div>
                <h4>{pageDescription}</h4>
                <div className="button-container">
                    {canGoForward && <button onClick={this.changePage(1)}>Next</button>}
                </div>
            </div>
            <Footer backAction={() => {
                if (fromAllResults) {
                    this.setState({
                        isSearching: false,
                        isShowingResults: false,
                        isShowingAllResults: false,
                        isShowingStateFilters: false,
                        isShowingAreaFilters: true,
                        isEmptyResult: false,
                    });
                } else {
                    this.setState({
                        isSearching: false,
                        isShowingResults: false,
                        isShowingAllResults: false,
                        isShowingStateFilters: true,
                        isShowingAreaFilters: false,
                        isEmptyResult: false,
                    });
                }

            }}/>
        </div>;
    }

    navigateToHonoreeDetails = (id) => {
        console.log("Honoree: " + id);
        this.searchForHonoree(id);
    }

    navigateToDonorDetails = (id) => () => {
        console.log("Donor: " + id);
        this.searchForDonors(id);
    }

    renderHonoreeItem = (item, key) => {
        return <div className="honoree-item"
                    key={key}
                    onClick={this.navigateToHonoreeDetails(item.id)}>
            {item.honoree}
        </div>
    }

    renderDonorItem = (item, key) => {
        return <div className="honoree-item"
                    key={key}
                    onClick={this.navigateToDonorDetails(item.id)}>
            {item.donor}
        </div>
    }

    renderPersonDetailsScreen() {
        const {bricks, city, state, donor, honoree, honorees, donors} = this.state.selectedPerson;
        console.log(this.state.selectedPerson);
        let line = "";
        if (this.state.selectedPerson.hasOwnProperty("donor")) {
            line = donor;
        } else {
            line = honoree;
        }
        return <div key={"person-screen"}>
            <div className="person-screen-details">

                <h4>{line}</h4>
                <h4>{city} {state}</h4>

                {donors && <h2>Honored by Donations From:</h2>}
                <PaginationList
                    cap={50}
                    click={this.navigateToDonorDetails}
                    items={donors}/>

                {honorees && <h2>Donations Given In Honor Or Memory Of:</h2>}
                {honorees &&
                <PaginationList
                    cap={50}
                    click={this.navigateToHonoreeDetails}
                    items={honorees}/>}

                {bricks && <h2>Bricks</h2>}
                {bricks &&
                <PaginationList
                    cap={5}
                    classRoot="brink-list"
                    renderMethod={this.renderBrickLink2}
                    click={this.navigateToHonoreeDetails}
                    items={bricks}/>}
            </div>


            <Footer backAction={() => {
                const backState = this.state.savedState;
                backState.selectedPerson = null;
                this.setState(backState);
            }}/>
        </div>;
    }


    renderPeopleResultsScreen() {
        const {results, pageSize, currentPage} = this.state;
        const items = [];
        for (let i = (currentPage * pageSize); i < Math.min((currentPage + 1) * pageSize, results.length); i++) {
            items.push(results[i]);
        }
        const lastPage = Math.ceil(results.length / pageSize);
        const canGoBack = currentPage !== 0;
        const canGoForward = (currentPage + 1) !== lastPage;
        const pageDescription = `Page ${currentPage + 1} of ${lastPage}`
        return <div key={"results-screen"}>
            <div key="results"
                 className="brick-list">
                {_.map(items, this.renderPersonLink)}
            </div>
            <div className="specialButtonArea">
                <button className="specialButton"
                        onClick={this.handleRefineSearch}>Refine Search
                </button>
            </div>
            <div className="paginationPanel">
                <div className="button-container">
                    {canGoBack && <button onClick={this.changePage(-1)}>Previous</button>}
                </div>
                <h4>{pageDescription}</h4>
                <div className="button-container">
                    {canGoForward && <button onClick={this.changePage(1)}>Next</button>}
                </div>
            </div>
            <Footer backAction={() => {
                this.setState({
                    isSearching: false,
                    isShowPeopleResults: false,
                });
            }}/>
        </div>;
    }

    renderPickOrigin = () => {
        const {selectedBrick} = this.state;
        const origins = ["Museum", "Museum Parking", "Chapel Parking"]
        return <div key="area-list"
                    className="selectionDialog">
            <h1>Where Are You Coming From?</h1>
            <div className="state-list">
                {_.map(origins, x => {
                    return <div key={x}
                                className="originButton"
                                onClick={() => {
                                    window.savedState = this.state;
                                    window.savedState.isPickingOrigin = false;
                                    window.savedState.brickNumber = null;
                                    navigate("brick/" + selectedBrick + "/" + x)
                                }}>
                        <h2>{x}</h2>
                    </div>
                })}
            </div>
            <Footer backAction={() => {
                const newState = this.state.savedState;
                delete newState.savedState;
                newState.isPickingOrigin = false;
                newState.isSearching = false;
                this.setState(newState);
            }}/>
        </div>;
    };

    renderEmptyResult = () => {
        return <div key={"query-screen"}
                    className={"flex-column"}>
            <h1>{this.state.title}</h1>
            <EagleIcon/>
            <input placeholder="Type your search"
                   value={this.state.query}
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           this.handleSearch();
                       }
                   }}
                   onChange={this.handleChange}/>
            <h1 class="error">We didn't find results with that criteria. Please try something else.</h1>
            <AppButton title="Search"
                       action={this.handleSearch}/>
            <Footer/>
        </div>
    };

    render() {
        const {
            isSearching,
            isShowingStateFilters,
            isShowingResults,
            isShowingAreaFilters,
            isPickingOrigin,
            isEmptyResult,
            isShowingAllResults,
            isShowPeopleResults,
        } = this.state;
        let subScreen = null;

        if (isEmptyResult) {
            subScreen = this.renderEmptyResult();
        } else if (isPickingOrigin) {
            subScreen = this.renderPickOrigin();
        } else if (isSearching) {
            subScreen = this.renderLoadingScreen();
        } else if (isShowingStateFilters) {
            subScreen = this.renderStateFilters();
        } else if (isShowingAreaFilters) {
            subScreen = this.renderAreaFilters();
        } else if (isShowingResults) {
            subScreen = this.renderResults(false);
        } else if (isShowingAllResults) {
            subScreen = this.renderResults(true);
        } else if (isShowPeopleResults) {
            if (this.state.selectedPerson) {
                subScreen = this.renderPersonDetailsScreen();
            } else {
                subScreen = this.renderPeopleResultsScreen();
            }
        } else {
            subScreen = this.renderQueryScreen();
        }
        return <div className="FindMyBrickScreen">
            {subScreen}
        </div>
    }
}
