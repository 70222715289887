import React, {Component} from 'react';
import EagleIcon from './EagleIcon';
import "./MenuScreen.scss";
import Footer from './Footer';

import {AppLink} from './Commons';
import ScreenContainer from "./ScreenContainer";

export default class MenuScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleSearch = () => {

    };

    render() {
        return <ScreenContainer>
            <div key="MenuScreen" className="MenuScreen">
                <h1>

                </h1>
                <EagleIcon/>
                <div className="menu-button-list">
                    <AppLink title="Find My Brick" route="find-my-brick-search" beforeClick={() =>{
                        delete window.savedState;
                    }}/>
                    <AppLink title="Donor Search" route="donor-search" beforeClick={() =>{
                        delete window.savedState;
                    }}/>
                    <AppLink title="Honoree Search" route="honoree-search" beforeClick={() =>{
                        delete window.savedState;
                    }}/>
                    <AppLink title="Park Features" route="landmark-directory" beforeClick={() =>{
                        delete window.savedState;
                    }}/>
                </div>
            </div>
            <Footer isHome={true}/>
        </ScreenContainer>
    }
}








