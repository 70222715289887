
const namespace = "marine-3/"

export const resolveTrailmarkerImage = (source) => {
    const scaped =
        source
            .replace(/'/g, "")
            .replace(/\s/g, "-")
            .replace(/-/g, "_")
            .toLowerCase();
    console.log("TrailmarkerImage =" + source + " => " + scaped);
    return namespace + scaped;
}

export const resolveLandmarkThumbnail = (source) => {
    if(source.isIntersection) {
        return resolveLandmarkFilename(source.fileName);
    } else {
        return resolveLandmarkNameImage(source.name);
    }
}

export const resolveLandmarkFilename = (source) => {
    const scaped = source.split("\n")[0]
        .replace(/'/g, "")
        .replace(/\s/g, "_")
        .replace(/\.[^/.]+$/, "");
    console.log("LandmarkFilename =" + source + " => " + scaped);
    return namespace + scaped;
}

export const resolveLandmarkNameImage = (source) => {
    const scaped = source.split("\n")[0].trim()
        .replace(/,/g, "")
        .replace(/’/g, "")
        .replace(/"/g, "")
        .replace(/“/g, "")
        .replace(/”/g, "")
        .replace(/'/g, "")
        .replace(/\//g, "")
        .replace(/\s/g, "_");
    console.log("LandmarkNameImage =" + source + " => " + scaped);
    return namespace + scaped;
}
