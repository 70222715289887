import {navigate} from "@reach/router";
import React from "react";

export  const AppButton = ({title, action}) =>  {
    return <button onClick={action}>
        {title}
    </button>
};

export  const AppLink = ({title, route, beforeClick}) =>  {
    return <button className={"menu-button"} onClick={()=> {
        window.scrollTo(0, 0);
        if(beforeClick) {
            beforeClick();
        }
        navigate(route);
    }}>
        {title}
    </button>
};

export  const TextInput = ({parent, placeholder, field}) =>  {
    return <input onChange={(e)=> {
        const change = {};
        change[field] = e.target.value;
        parent.setState(change);
    }} placeholder={placeholder} value={parent.state[field]} />
};

