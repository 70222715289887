import React, {Component} from 'react';
import "./BrickLocationScreen.scss";

import axios from "axios";
import LoadingIcon from "./LoadingIcon";
import _ from "lodash";
import QRCode from 'qrcode'
import Footer from "./Footer";

import {resolveLandmarkNameImage, resolveTrailmarkerImage} from './tools.js';
import {fill} from "@cloudinary/base/actions/resize";
import {Cloudinary} from "@cloudinary/base";
import {resolveLandmarkFilename} from "./tools";

const cld = new Cloudinary({
    cloud: {
        cloudName: 'merthin'
    }
});


const capitalize = (s) => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1)
};

export const getLandmark = (item)  => {
    if(item.hasOwnProperty("landmarks")) {
        return item.landmarks[0];
    }
    return item.landmark;
}

export const getLandmarkCount =  (item) => {
    if(item.hasOwnProperty("landmarks")) {
        return item.landmarks.length;
    }
    return 1;
}

export default class BrickLocationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brickId: this.props.brickId,
            isLoading: true,
            isPathReady: false,
            isError: false,
            donorCity: "",
            donorState: "",
            honor: "",
            donor: "",
            origin: this.props.originName,
            results: null,
            steps: [],
            destinationName: null,
            originName: null,
            brickInfo: null,
            map: null,
            barcode: null,
        }
        const variable = 45;
    }

    componentDidMount() {
        this.performSearch();
    }

    performSearch = async () => {
        const {origin, brickId} = this.state;
        const uri = `https://us-central1-ak-mapping-api.cloudfunctions.net/path_narrative?originSectionName=${origin}&destinationBrickNumber=${brickId}`;
        try {
            this.setState({
                isLoading: true,
            });
            const config = {
                headers: {
                    authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
                }
            };
            const result = await axios.get(uri, config);
            this.processPathResult(result.data);
        } catch (error) {
            alert("There was an error performing the search. Please try again.");
        }
    };

    processPathResult = async (result) => {
        if (result.code === "error_path_not_found") {
            this.setState({
                isError: true,
                code: result.code
            });
        } else {
            const {destinationName, originName, steps} = result.data;
            const uri = window.location.href;
            const barcode = await QRCode.toDataURL(uri);
            const lastStep = steps[steps.length - 1];
            const brickInfo = lastStep.brick;
            this.setState({
                destinationName,
                originName,
                steps,
                barcode,
                brickInfo,
                isLoading: false,
                isPathReady: true,
                svg: result.data.svg
            });
        }
    };


    getImagePath = (name) => {
        const image = cld.image(name);
        image.resize(fill().width(350).height(250));
        return image.toURL();
    }

    renderStep = (item, key) => {
        if (key === 0) {
            return null;
        }

        const blocks = _.map(item.landmarks, (x,i) => {
            let imagePath = '';
            if(x.isIntersection) {
                imagePath = resolveLandmarkFilename(x.fileName);
            } else {
                imagePath = resolveLandmarkNameImage(x.name);
            }
            const imageUrl = this.getImagePath(imagePath);
            return <div className="stepBlock"
                 key={key + " " + i}>
                {i === 0 && <div className="stepNumber">
                    {key}
                </div>}
                {i > 0 && <div className="false-stepNumber"/>}
                <div className="stepImage">
                    <object data={imageUrl}
                            type="image/jpg">
                        <img className="defaultImage"
                             src={"/images/design/MHS-logo-white.png"}
                             width={350}
                             height={250}/>
                    </object>
                </div>
                <div className="stepDescription">
                    {this.getDebugInfo(item, imagePath)}
                    {x && <div>{x.name}</div>}
                    {x && <div>{x.instructions}</div>}
                    {item.section && <div>{item.section}</div>}
                </div>
            </div>
        });

        return blocks;
    }

    getDebugInfo = (item, imagePath) => {
        return null;
        const landmark = getLandmark(item);
        return <div className="debug">
            {landmark && <strong>Name: {resolveLandmarkNameImage(landmark.name)}</strong>}<br/>
            {landmark && <strong>Filename: {resolveLandmarkFilename(landmark.fileName)}</strong>}<br/>
            {landmark && <strong>Cloudinary: {imagePath}</strong>}<br/>
        </div>
    }

    renderLastInstruction() {
        const {steps} = this.state;
        const {brickNumber, locationDescription, trailMarker} = this.state.brickInfo;
        let lastText = `You have arrived at  Brick No. ${brickNumber}.`;
        if (locationDescription.length > 0) {
            lastText = "Please find the brick " + locationDescription;
        }
        if (lastText.lastIndexOf(".") !== lastText.length - 1) {
            lastText += "."
        }
        lastText += " Thank you for using the Marine Corps Heritage Foundation's Commemorative Brick Locator. We are grateful for your support of our mmission to preserve and share Marine Corps history.";

        const publicImageId = resolveTrailmarkerImage(trailMarker);
        const imageUrl = this.getImagePath(publicImageId);

        return <div className="stepBlock"
                    key={"lastStep"}>
            <div className="stepNumber">
                {steps.length}
            </div>
            <div className="stepImage">
                <object data={imageUrl}
                        type="image/jpg">
                    <img className="defaultImage"
                         src={"/images/design/MHS-logo-white.png"}
                         width={350}
                         height={250}/>
                </object>
            </div>
            <div className="stepDescription">
                <h2>Brick No. {brickNumber}</h2>{lastText}
            </div>
        </div>
    }

    renderBrickInfo() {
        const {
            brickNumber,
            donor,
            donorCity,
            donorState,
            locationDescription,
            description,
            inscriptionLine1,
            inscriptionLine2,
            inscriptionLine3,
        } = this.state.brickInfo;
        const svgStr = this.state.svg;
        return <div className="BrickLocationScreen">
            <div className="brickInfo">
                <div className="brickNumber">
                    N0. {brickNumber}
                </div>
                <div className="brickDescription">
                    <p>{inscriptionLine1}</p>
                    <p>{inscriptionLine2}</p>
                    <p>{inscriptionLine3}</p>
                </div>
                <div className="donor">
                    {donor}<br/>
                    {donorCity}, {donorState}
                </div>
            </div>
            <div className="map"
                 dangerouslySetInnerHTML={{__html: svgStr}}/>
            <div className="locationDescription">
                <div className="locationDescription1">Located on the {description} {locationDescription}</div>
            </div>
            <div className="steps">
                <img src={this.state.barcode}
                     className="barcode"
                     alt={"Barcode"}/>
                {_.map(this.state.steps, this.renderStep)}
                {this.renderLastInstruction()}
            </div>
            <Footer
                showHomeButton={true}
                showPrintButton={true}
                backAction={() => {
                    window.history.back()
                }}/>
        </div>
    }

    renderError() {
        return <div className="BrickLocationScreen">
            <div className="Communication">
                <h1>No bricks currently placed in Semper Fidelis Memorial Park match your criteria.</h1>
                <p>Please note that the brick locator only contains information for engraved bricks already placed along
                    the pathways of Semper Fidelis Memorial Park.</p>
                <p>Bricks that have been purchased, but are not yet installed in the park do not appear in the brick
                    locator.</p>
                <p>If you have any questions regarding the status of a brick, please contact the Marine Corps Heritage
                    Foundation's Development Services staff, toll free, at 800-397-7585.</p>
            </div>
            <Footer/>
        </div>;
    }

    renderSearching() {
        return <div>
            <LoadingIcon/>
        </div>;
    }

    render() {
        if (this.state.isError) {
            return this.renderError();
        }
        if (this.state.isLoading) {
            return this.renderSearching();
        }
        if (this.state.isPathReady) {
            return this.renderBrickInfo();
        }
    }
}








