import './LoadingIcon.scss';
import React, {Component} from 'react';

export default class LoadingIcon extends Component {
    render() {
        return <div className="LoadingIcon">
            <img src="/images/design/MHBF-animation.gif" className="Animation" alt={"Loading"}/>
        </div>
    }
}
