import React, {Component} from 'react';
import "./SectionLocationScreen.scss";
import {navigate} from "@reach/router";

import axios from "axios";
import LoadingIcon from "./LoadingIcon";
import _ from "lodash";
import QRCode from 'qrcode'
import Footer from "./Footer";

import {fill} from "@cloudinary/base/actions/resize";
import {Cloudinary} from "@cloudinary/base";
import {resolveLandmarkFilename, resolveLandmarkNameImage} from "./tools";
import {getLandmark} from "./BrickLocationScreen";

const cld = new Cloudinary({
    cloud: {
        cloudName: 'merthin'
    }
});

export default class SectionLocationScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionName: this.props.sectionName,
            titleName: this.props.titleName,
            origin: this.props.originName,
            sectionInfo: null,
            isLoading: true,
            isPathReady: false,
            isError: false,
            results: null,
            steps: [],
            destinationName: null,
            originName: null,
            brickInfo: null,
            map: null,
            barcode: null,
        }
    }

    componentDidMount() {
        this.performSearch();
    }

    performSearch = async () => {
        const {origin, sectionName} = this.state;
        const uri = `https://us-central1-ak-mapping-api.cloudfunctions.net/path_narrative_to_section?originSectionName=${origin}&destinationSectionName=${sectionName}`;
        try {
            this.setState({
                isLoading: true,
            });
            const config = {
                headers: {
                    authorization: "ba25204cd8824320ef518b905689714dea13f6c27b999f467e298288f20e9e185046a946dc9f45c2f1b49bef21e50eabfa7680054073c08c7a9ec0527f4dd9cf3d1713e4e206776c5f75873261d655d16cdbbba17071f77bc4c4de47ccb3b191eb5b498d50b2cf3e0339b36e90ed7d53361c1578b7205ca1539b17d2bdd21dbc772fda39c170c8b30bc2dc916db08e4bc8be4bfe1e68a587d880840f880cbc95"
                }
            };
            const result = await axios.get(uri, config);
            this.processPathResult(result.data);
        } catch (error) {
            alert("There was an error performing the search. Please try again.");
        }
    };

    processPathResult = async (result) => {
        if (result.code === "error_path_not_found") {
            this.setState({
                isError: true,
                code: result.code
            });
        } else {
            console.log(result.data);
            const {destinationName, originName, steps} = result.data;
            const uri = window.location.href;
            const barcode = await QRCode.toDataURL(uri);
            const sectionInfo = steps[steps.length - 1];
            this.setState({
                destinationName,
                originName,
                steps,
                barcode,
                sectionInfo,
                isLoading: false,
                isPathReady: true,
                svg: result.data.svg
            });
        }
    };

    getImagePath = (name) => {
        const image = cld.image(name);
        image.resize(fill().width(350).height(250));
        return image.toURL();
    }


    renderStep = (item, key) => {
        console.log(item);
        if (key === 0) {
            return null;
        }

        let imagePath = '';

        const landmark = getLandmark(item);

        if(landmark.isIntersection) {
            imagePath = resolveLandmarkFilename(landmark.fileName);
        } else {
            imagePath = resolveLandmarkNameImage(landmark.name);
        }

        const imageUrl = this.getImagePath(imagePath);


        return <div className="stepBlock"
                    key={key}>
            <div className="stepNumber">
                {key}
            </div>
            <div className="stepImage">
                {landmark &&
                <object data={imageUrl}
                        type="image/jpg">
                    <img className="defaultImage"
                         src={"/images/design/MHS-logo-white.png"}
                         width={350}
                         height={250}/>
                </object>
                }
            </div>
            <div className="stepDescription">
                {landmark && <h2>{landmark.name}</h2>}
                {landmark && <p>{landmark.instructions}</p>}
                {item.section && <p>{item.section}</p>}
            </div>
        </div>
    }

    resolveImage = (source) => {
        const scaped = source.split("\n")[0].replace(/'/g,"_").replace(/\s/g, "_").replace(/\.[^/.]+$/, "");
        return "marine-images/" + scaped;
    }

    renderLastInstruction() {
        const {steps, titleName} = this.state;
        return <div className="stepBlock Final"
                    key={"lastStep"}>
            <div className="stepNumber">
                {steps.length}
            </div>
            <div className="stepDescription">
                You have arrived at "{titleName}". Thank you for using the Museum’s Brick Finder.
            </div>
        </div>
    }

    renderSectionInfo() {
        const {
            titleName,
        } = this.state;
        const svgStr = this.state.svg;
        return <div className="SectionLocationScreen">
            <div className="brickInfo">
                <div className="brickNumber">

                </div>
                <div className="brickDescription">{titleName}</div>
                <div className="brickNumber">

                </div>
            </div>
            <div className="map"
                 dangerouslySetInnerHTML={{__html: svgStr}}/>
            <div className="steps">
                <img src={this.state.barcode}
                     className="barcode"
                     alt={"Barcode"}/>
                {
                    _.map(this.state.steps, this.renderStep)
                }
                {this.renderLastInstruction()}
            </div>
            <Footer backAction={() => {
                navigate('/landmark-directory');
            }}
                    showHomeButton={true}
                    showPrintButton={true}/>
        </div>
    }

    renderError() {
        return <div className="SectionLocationScreen">
            <div className="Communication">
                <h1>No sections currently placed in Semper Fidelis Memorial Park match your criteria.</h1>
                <p>
                    Please note that the brick locator only contains information for engraved bricks already placed
                    along the pathways of Semper Fidelis Memorial Park.
                </p>
                <p>
                    Bricks that have been purchased, but are not yet installed in the park do not appear in the brick
                    locator.
                </p>
                <p>
                    If you have any questions regarding the status of a brick, please contact the Marine Corps Heritage
                    Foundation's Development Services staff, toll free, at 800-397-7585.
                </p>
            </div>
            <Footer backAction={() => {
                navigate('landmark-directory');
            }}/>
        </div>;
    }

    renderSearching() {
        return <div>
            <LoadingIcon/>
        </div>;
    }

    render() {
        if (this.state.isError) {
            return this.renderError();
        }
        if (this.state.isLoading) {
            return this.renderSearching();
        }
        if (this.state.isPathReady) {
            return this.renderSectionInfo();
        }
    }
}








