import './Footer.scss';
import React, {Component} from 'react';
import {navigate} from "@reach/router";

export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
           showHomeButton: props.showShowButton || true,
           showPrintButton: props.showPrintButton || false,
           isHome: props.isHome || false,
        };
    }
    renderButton(caption, icon, route, action, identifier = ""){
        const iconPath = "/images/design/" + icon;
        const iconStyles = {backgroundImage: "url(" + iconPath + ")"};
        return <button className={`footer-button ${identifier}`} onClick={() => {
            if(action) {
                action();
            } else {
                navigate(route);
            }
        }}>
            <div className={"img"} style={iconStyles}></div>
            <div className={"caption"}>{caption}</div>
        </button>;
    }
    renderHomeButton() {
        return this.renderButton("Home", "home_icon.svg", null, () => {
            if(this.state.isHome) {
                //TODO: Add CED Config.
                window.location.href = "https://semperfidelispark.org/"
            } else {
                navigate("/");
            }
        });
    }
    renderPrintButton() {
        return this.renderButton("Print", "print_icon.svg", null, () => {
            window.print();
        }, "print-button");
    }
    renderBackButton() {
        return this.renderButton("Back", "back_icon.svg", null, this.props.backAction);
    }
    render() {
        return <div className="Footer no-print">
            {this.state.showHomeButton && this.renderHomeButton()}
            {this.state.showPrintButton && this.renderPrintButton()}
            {this.props.backAction && this.renderBackButton()}
        </div>
    }
}
