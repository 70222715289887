export const landmarks = [
    {
        "fileName": "Intersection K - Chapel to swtchbk to Taylor\n\n",
        "instructions": "At this intersection continue straight to remain on the Chapel Trail in the direction of the Crucible switchbacks",
        "name": "Chapel Trail to Crucible switchbacks",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1572988954150",
        "prevSectionId": "1572990172847"
    },
    {
        "fileName": "Intersection G - DDT at Marine awayfrom NMMC",
        "instructions": "At the intersection, turn right for the Devil Dog Trail.",
        "name": "Marine Trail to Devil Dog Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571159260752",
        "prevSectionId": "1571154382901"
    },
    {
        "fileName": "Osprey",
        "instructions": "In 2000, 23 Marines were killed onboard the controversial V-22 Osprey Tiltrotor aircraft. On April 8 in arana, Arizona, an Osprey went down while training as part of an OPEVAL mission simulating a night-time rescue, killing all 19 on board. On December 11 of the same year, another Osprey crashed into a forest in Jacksonville, NC, killing all 4 onboard.",
        "name": "Osprey Memorial",
        "sectionId": "1571066147361",
        "sectionName": "133"
    },
    {
        "fileName": "Devil Dog Trail Memorial Wall",
        "instructions": null,
        "name": "Devil Dog Trail Memorial Wall",
        "sectionId": "1571159260752",
        "sectionName": "205"
    },
    {
        "fileName": "General Carl Mundy, Jr. Memorial Bench",
        "instructions": null,
        "name": "General Carl Mundy, Jr. Memorial Bench",
        "sectionId": "1571161478341",
        "sectionName": "227"
    },
    {
        "fileName": "Intersection J - uphill Lwr Chpl to Swtchbks",
        "instructions": "Continue up the hill along the Chapel Trail switchbacks.",
        "name": "Chapel Trail Switchbacks",
        "sectionId": "1571161067280",
        "sectionName": "225",
        "isIntersection": true,
        "nextSectionId": "1571161154989",
        "prevSectionId": "1571161057670"
    },
    {
        "fileName": "LtGen Lewis B. Chesty Puller",
        "instructions": "Lieutenant General Lewis “Chesty” Puller is the most decorated combat Marine in history. During his esteemed career, he fought in Nicaragua and Haiti, as well as some of the bloodiest battles of World War II and the Korean War. He is the only Marine to be awarded five Navy Crosses, and has become a symbol of esprit de corps to the Marines",
        "name": "Lieutenant General Lewis B. “Chesty” Puller ",
        "sectionId": "1572990865849",
        "sectionName": "104"
    },
    {
        "fileName": "G-3-1 Korea",
        "instructions": "George Company, 3rd Battalion, 1st Marines served in the Korean War beginning in 1950 at the Battle of Inchon. Upon the recapture of Seoul, they relocated to the east coast of Korea and fought in the Battle of Chosin Reservoir. The unit fought in Korea until 1953 and participated in the Korean Demilitarized Defense until 1955",
        "name": "G-3-1 Korea ",
        "sectionId": "1572991043202",
        "sectionName": "118"
    },
    {
        "fileName": "USS Arizona",
        "instructions": "Steel from the USS Arizona is encased in this monument, which honors those Marines killed in the attack on Pearl Harbor on December 7, 1941. Of the 88 Marines in the detachment onboard the USS Arizona, only 15 survived the attack. Also listed are those killed in action onboard other vessels in Pearl Harbor",
        "name": "USS Arizona",
        "sectionId": "1571159268361",
        "sectionName": "207"
    },
    {
        "fileName": "1stBn 1stMar 1stDiv",
        "instructions": "Dedicated to the more than 567 Marines and Corpsmen serving with 1st Battalion 1st Marines that perished at their posts in Vietnam, 1965–1971",
        "name": "1st Battalion 1st Marines 1st Marine Division",
        "sectionId": "1571152615560",
        "sectionName": "147"
    },
    {
        "fileName": "Intersection H - DAY - Park or Chapel\n\n",
        "instructions": "At the intersection, take the fork to the right to remain on the Marine Trail and head in the direction of the Devil Dog Trail Memorial Wall.",
        "name": "Continue on Marine Trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571160480718",
        "prevSectionId": "1571160622332"
    },
    {
        "fileName": "China Marines",
        "instructions": "For more than 125 years, the Marine Corps maintained a presence in or around China (1819–1949.) Some were aboard warships protecting American trade, while others experienced China in river gunboats or in cities like Peking and Shanghai. Forty received Medals of Honor and 15 became Commandants of the Marine Corps",
        "name": "China Marines ",
        "sectionId": "1572991030043",
        "sectionName": "114"
    },
    {
        "fileName": "Dog Co 2ndBn 7thMarReg 1stMar Bench",
        "instructions": null,
        "name": "Dog Co., 2dBn, 7th Mar Memorial Bench",
        "sectionId": "1572991030043",
        "sectionName": "114"
    },
    {
        "fileName": "Purple Foxes.Hill 881S",
        "instructions": "HMM-364 is a Marine Medium Helicopter Squadron known as the “Purple Foxes.” From 1964 to 1969, the Purple Foxes served by flying re-supply, troop-lifts and medevacs twenty-four hours a day. 881 South On January 20, 1968, US Marines went against the Northern Vietnamese Army in what became one of the bloodiest battles of the Vietnam War, the Battle of Hill 881. The battle in the I Corps Tactical Zone was one of the “hill fights” in defense of Khe Sanh from December 1967 to April 1968",
        "name": "HMM-364 ",
        "sectionId": "1571152622585",
        "sectionName": "148"
    },
    {
        "fileName": "5th BOC 1948 ",
        "instructions": "The 228 members of the 5th Officers Basic Class were the only officers commissioned in 1948. About 75 percent were commissioned from enlisted ranks, and 90 percent went on to serve in the Korean and Vietnam Wars, where 23 were killed in action",
        "name": "5th Officers Basic Class 1948 ",
        "sectionId": "1572991050306",
        "sectionName": "120"
    },
    {
        "fileName": "Cpl and Mrs Robert Skinner Bench",
        "instructions": null,
        "name": "Cpl and Mrs Robert Skinner Bench",
        "sectionId": "1571153782548",
        "sectionName": "195"
    },
    {
        "fileName": "Marine Veterans Memorial",
        "instructions": "Marine Veterans Memorial In memory of all United States Marines who died in the service of their country",
        "name": "Marine Veterans Memorial",
        "sectionId": "1571152950632",
        "sectionName": "153"
    },
    {
        "fileName": "Intersection H - Day from Chapel to Marine\n\n",
        "instructions": "At the intersection, continue straight to follow the Marine Trail and head in the direction of the Devil Dog Trail Memorial Wall.",
        "name": "Chapel Trail to Marine Trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571160480718",
        "prevSectionId": "1571161007406"
    },
    {
        "fileName": "The Fegan and Waldruff Bench",
        "instructions": null,
        "name": "The Fegan and Waldruff Bench",
        "sectionId": "1571066159489",
        "sectionName": "135"
    },
    {
        "fileName": "Chapel Plaza Restrooms\n\n",
        "instructions": "Chapel Plaza Restrooms",
        "name": "Chapel Plaza Restrooms",
        "sectionId": "1572990331984",
        "sectionName": "91"
    },
    {
        "fileName": "Intersection B - BdF and footprints towards NMMC\n\n",
        "instructions": "At the intersection between the Esprit de Corps Footprints and the Blvd de France trail, turn right to walk back towards the Museum entrance plaza.",
        "name": "Footprints to Blvd de France",
        "sectionId": "1572992488508",
        "sectionName": "L",
        "isIntersection": true,
        "nextSectionId": "1572993322966",
        "prevSectionId": "1572989733095"
    },
    {
        "fileName": "Empty Rally Point (by FDNY)",
        "instructions": "Future site of…",
        "name": "Empty Rally Point",
        "sectionId": "1571153638876",
        "sectionName": "173"
    },
    {
        "fileName": "Sgt Reckless",
        "instructions": "Sgt Reckless carried ammunition to the front lines for the 75mm Recoilless Rifle Platoon of the 5th Marines. Perhaps her most valiant effort was in the Battle of Outpost Vegas in March 1953, in which she was wounded twice and carried over 9,000 pounds of ammunition. Reckless earned multiple medals and was eventually promoted to SSgt in 1959 by Gen Pate, 21st Commandant of the Marine Corps",
        "name": "Sgt Reckless",
        "sectionId": "1571153470136",
        "sectionName": "163"
    },
    {
        "fileName": "Intersection B - BdF and Footprints towards Lejeune",
        "instructions": "When you reach the intersection of the Blvd de France and the Esprit de Corps footprints, take an immediate left to the Crucible switchbacks",
        "name": "Blvd de France to Crucible switchbacks",
        "sectionId": "1572992488508",
        "sectionName": "L"
    },
    {
        "fileName": "Entrance marker SFMP",
        "instructions": "Semper Fidelis Memorial Park can also be entered at this point near the Molly Marine monument",
        "name": "Entrance to SFMP at Molly Marine",
        "sectionId": "1572991074657",
        "sectionName": "128"
    },
    {
        "fileName": "Intersection E - OKH.DDT away from NMMC",
        "instructions": "At the intersection, turn left for the Devil Dog Trail.",
        "name": "Old King's Highway to Devil Dog Trail",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571153176167",
        "prevSectionId": "1571152965593"
    },
    {
        "fileName": "The Boys of 67",
        "instructions": "The graduates of Basic Officers Class 5-67 were immediately sent to Vietnam and served in the Fleet Marine Force– Pacific, where 39 classmates were killed in action. Members of the class received a total of 6 Navy Crosses and 27 Silver Stars",
        "name": "The Boys of ’67 ",
        "sectionId": "1572991059571",
        "sectionName": "123"
    },
    {
        "fileName": "TBS 1-57",
        "instructions": "This monument, which contains a time capsule, represents the 327 graduates of the Basic School Class 1-57 at Quantico. Many went on to serve in Vietnam, where one classmate was killed",
        "name": "Basic School Class 1-57 ",
        "sectionId": "1571161007406",
        "sectionName": "217"
    },
    {
        "fileName": "Intersection G - DDT to Marine",
        "instructions": "At the intersection, turn left on Marine Trail to head back towards the Museum.",
        "name": "Devil Dog Trail to Marine Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571154382901",
        "prevSectionId": "1571159260752"
    },
    {
        "fileName": "Intersection Q - puller to chpl entr OR chpl trail\n\n",
        "instructions": "At this intersection, continue straight to remain on the Chapel Trail",
        "name": "Chapel Trail from Puller",
        "sectionId": "1572990328401",
        "sectionName": "90",
        "isIntersection": true,
        "nextSectionId": "1572990209754",
        "prevSectionId": "1572990304138"
    },
    {
        "fileName": "LtCol James B. Chandler Memorial Bench",
        "instructions": null,
        "name": "LtCol James B. Chandler Memorial Bench",
        "sectionId": "1572992488508",
        "sectionName": "L"
    },
    {
        "fileName": "Linda White Memorial Bench",
        "instructions": null,
        "name": "Linda White Memorial Bench",
        "sectionId": "1571153782548",
        "sectionName": "195"
    },
    {
        "fileName": "Intersection H - DAY - Park or Chapel\n\n",
        "instructions": "At the intersection, turn left to follow the Chapel Trail towards the Chosin Reservoir Memorial.",
        "name": "Marine Trail to Chapel Trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571161007406",
        "prevSectionId": "1571160622332"
    },
    {
        "fileName": "Intersection D - Marine to Old King's Hwy",
        "instructions": "At the intersection, follow the fork to the left to continue on the Marine Trail.",
        "name": "Remain on Marine Trail",
        "isIntersection": true,
        "sectionId": "1571152343583",
        "sectionName": "141"
    },
    {
        "fileName": "HMM-362 Ugly Angels ",
        "instructions": "Formerly known as Archie’s Angels under the command of LtCol Archie Clapp, the Ugly Angels were the first Marine aircraft unit to serve in Vietnam. The Ugly Angels lost 33 Marines while serving in Soc Trang, Ky Ha, Marble Mountain and Hue/Phu Bai until 1969. They also supported operations in Vietnam from the sea onboard the USS Iwo Jima, USS Okinawa and USS Princeton",
        "name": "HMM-362 Ugly Angels ",
        "sectionId": "1572990310577",
        "sectionName": "44"
    },
    {
        "fileName": "Intersection C - BdF to Marine\n\n",
        "instructions": "The Marine Trail begins just beyond the LtGen John A. Lejeune rally point and curves to the left.",
        "name": "Start of Marine Trail",
        "sectionId": "1571065956547",
        "sectionName": "129",
        "isIntersection": true,
        "nextSectionId": "1571065956547",
        "prevSectionId": "1572992504125"
    },
    {
        "fileName": "Intersection T - LWR chpl entr plaza to upr chpl trl",
        "instructions": "On the other side of the entrance plaza for the chapel is the intersection between the switchbacks and the Chapel Trail.",
        "name": "Intersection of Chapel plaza switchback and Chapel Plaza ",
        "sectionId": "1572990336650",
        "sectionName": "92",
        "isIntersection": true,
        "nextSectionId": "1572990331984",
        "prevSectionId": "1572990347956"
    },
    {
        "fileName": "Intersection B - BdF and Footprints towards Lejeune",
        "instructions": "Where the Blvd de France meets the Esprit de Corps Footprints at the base of the Crucible switchbacks, continue straight towards the Lejeune statue and the beginning of the Marine Trail",
        "name": "Remain on Blvd de France",
        "isIntersection": true,
        "sectionId": "1572992488508",
        "sectionName": "L"
    },
    {
        "fileName": "Intersection T- LWR chpl entr plaza to upr chpl entr swtchbk",
        "instructions": "On the other side of the entrance plaza for the chapel is the Chapel entrance switchback. There are engraved bricks located along this section of trail.",
        "name": "Intersection of Chapel plaza and Chapel Plaza switchback",
        "sectionId": "1572990336650",
        "sectionName": "92",
        "isIntersection": true,
        "nextSectionId": "1572990347956",
        "prevSectionId": "1572990331984"
    },
    {
        "fileName": "1st Recon Bn 1stMarDiv",
        "instructions": "Established in 1941, the 1st Reconnaissance Battalion, 1st Division provides task organized forces that conduct amphibious and ground reconnaissance, battle space shaping operations, raids, and specialized insertion and extraction. It has served in WWII, Korea, Vietnam, the Persian Gulf War and the Global War on Terror",
        "name": "1st Reconnaissance Battalion, 1st Marine Division",
        "sectionId": "1571161497995",
        "sectionName": "230"
    },
    {
        "fileName": "Vanous Family Bench",
        "instructions": null,
        "name": "Vanous Family Bench",
        "sectionId": "1572990307673",
        "sectionName": "43"
    },
    {
        "fileName": "Intersection H - Day from Chapel to Marine",
        "instructions": "At the intersection, take the fork to the right to follow the Marine Trail back towards the Museum",
        "name": "Marine Trail cross trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571160622332",
        "prevSectionId": "1571161007406"
    },
    {
        "fileName": "Intersection L - Taylor to N Crucible\n\n",
        "instructions": "From the Taylor Overlook, follow the trail to your right to follow the North Crucible switchbacks down the hill.",
        "name": "Chapel Trail to Crucible North switchbacks",
        "sectionId": "1572988962822",
        "sectionName": "31",
        "isIntersection": true,
        "nextSectionId": "1572989183719",
        "prevSectionId": "1572988960230"
    },
    {
        "fileName": "Intersection L - Taylor to chapel",
        "instructions": "The Taylor overlook is located at the top of the hill. To continue along the Chapel Trail, continue straight towards the Semper Fidelis Memorial Chapel.",
        "name": "Taylor Overlook: Crucible to Chapel Trail",
        "sectionId": "1572988962822",
        "sectionName": "31",
        "isIntersection": true,
        "nextSectionId": "1572988960230",
        "prevSectionId": "1572989063391"
    },
    {
        "fileName": "Mr and Mrs Laverne Watkins Memorial Bench",
        "instructions": null,
        "name": "Mr and Mrs Laverne Watkins Memorial Bench",
        "sectionId": "1572991030043",
        "sectionName": "114"
    },
    {
        "fileName": "Intersection P - UPR chpl entr plaza to upr chpl trl",
        "instructions": "On the other side of the entrance plaza for the chapel is the beginning of the Chapel Trail.",
        "name": "Intersection of Chapel plaza switchback and Chapel Plaza ",
        "sectionId": "1572990384808",
        "sectionName": "100",
        "isIntersection": true,
        "nextSectionId": "1572990209754",
        "prevSectionId": "1572990378754"
    },
    {
        "fileName": "Leo Freudberg Memorial Bench",
        "instructions": null,
        "name": "Leo Freudberg Memorial Bench",
        "sectionId": "1571152615560",
        "sectionName": "147"
    },
    {
        "fileName": "Intersection F - Marine to Historic OKH",
        "instructions": "Continue along the paved trail to follow the Marine Trail. The gravel road follows the historic \"Potomac Path,\" later known as King's Highway, upon which George Washington and French troops travelled to Yorktown during the Revolutionary War.",
        "name": "Old King's Highway to Marine Trail",
        "sectionId": "1571153470136",
        "sectionName": "163",
        "isIntersection": true,
        "nextSectionId": "1571153501287",
        "prevSectionId": "1571153469238"
    },
    {
        "fileName": "Tankers Association",
        "instructions": "This monument honors all combat tankers who have participated in every battle where Marine tanks were committed in support of a Marine infantry unit from World War II to the present",
        "name": "The Tank Infantry Team ",
        "sectionId": "1571152970943",
        "sectionName": "157"
    },
    {
        "fileName": "Memorial Bridge",
        "instructions": "The Marine Trail continues across the Memorial Bridge.",
        "name": "Memorial Bridge",
        "sectionId": "1571152272190",
        "sectionName": "137"
    },
    {
        "fileName": "HML-HMLA-167",
        "instructions": "HML/HMLA-167 was the only Marine Helicopter squadron formed in a combat zone in April 1968 in the Republic of Vietnam. This memorial honors the 47 Marines who gave all serving with HMLA-167",
        "name": "HML-HMLA-167",
        "sectionId": "1571161512237",
        "sectionName": "233"
    },
    {
        "fileName": "Intersection J - dwnhill Swtchbk to lwr chpl",
        "instructions": "Continue straight along the Chapel Trail.",
        "name": "Chapel Trail Switchbacks",
        "sectionId": "1571161067280",
        "sectionName": "225",
        "isIntersection": true,
        "nextSectionId": "1571161057670",
        "prevSectionId": "1571161154989"
    },
    {
        "fileName": "Intersection Q - puller to chpl entr OR chpl trail\n\n",
        "instructions": "At the intersection, take the fork to the right towards the Chapel Restrooms and lower Chapel Plaza switchback.",
        "name": "Chapel Trail to Chapel plaza switchback",
        "sectionId": "1572990328401",
        "sectionName": "90",
        "isIntersection": true,
        "nextSectionId": "1572990331984",
        "prevSectionId": "1572990209754"
    },
    {
        "fileName": "Intersection H - Day from Marine to Chapel",
        "instructions": "At the intersection, continue straight to follow the Lower Chapel Trail to the Chapel Trail switchbacks",
        "name": "Marine Trail to Chapel Trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571161007406",
        "prevSectionId": "1571160480718"
    },
    {
        "fileName": "Intersection S - entr to SFMP at Molly\n\n",
        "instructions": "Enter Semper Fidelis Memorial Park at the Chesty Puller Trail",
        "name": "Entrance to SFMP and Chesty Puller Trail",
        "sectionId": "1572991074657",
        "sectionName": "128",
        "isIntersection": true,
        "nextSectionId": "1572991072914",
        "prevSectionId": "1572991074657"
    },
    {
        "fileName": "Intersection N - chapel trail to chapel entr plaza",
        "instructions": "At the intersection, turn right towards the Semper Fidelis Memorial Chapel entrance",
        "name": "Chapel Trail to Chapel entrance plaza",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990384808",
        "prevSectionId": "1572990199384"
    },
    {
        "fileName": "Intersection E - OKH.DDT away from NMMC\n\n",
        "instructions": "At the intersection, continue straight to stay on the Old King's Hwy Trail.",
        "name": "Remain on Old King's Highway",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571153186215",
        "prevSectionId": "1571152965593"
    },
    {
        "fileName": "Marine Trail Memorial Wall",
        "instructions": "Hung along the Marine Trail Memorial Wall, is a variety of memorial plaques representing various Marine Corps groups and units.",
        "name": "Marine Trail Memorial Wall",
        "sectionId": "1571066159489",
        "sectionName": "135"
    },
    {
        "fileName": "Intersection L - S Crucible to Taylor",
        "instructions": "From the Taylor Overlook, follow the trail to your right to join the Chapel Trail",
        "name": "Crucible South switchbacks to Chapel Trail",
        "sectionId": "1572988962822",
        "sectionName": "31",
        "isIntersection": true,
        "nextSectionId": "1572988960230",
        "prevSectionId": "1572989063391"
    },
    {
        "fileName": "Ripleys Raiders",
        "instructions": "In 2000, 23 Marines were killed onboard the controversial V-22 Osprey Tiltrotor aircraft. On April 8 in arana, Arizona, an Osprey went down while training as part of an OPEVAL mission simulating a night-time rescue, killing all 19 on board. On December 11 of the same year, another Osprey crashed into a forest in Jacksonville, NC, killing all 4 onboard.",
        "name": "Ripley's Raiders",
        "sectionId": "1571152278617",
        "sectionName": "138"
    },
    {
        "fileName": "Waleria Went Memorial Bench",
        "instructions": null,
        "name": "Waleria Went Memorial Bench",
        "sectionId": "1571161518530",
        "sectionName": "235"
    },
    {
        "fileName": "Intersection G - DDT at Marine to NMMC",
        "instructions": "At the intersection, continue straight as you pass the Devil Dog Trail Memorial Wall to remain on the Marine Trail.",
        "name": "Remain on Marine Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571154382901",
        "prevSectionId": "1571153799662"
    },
    {
        "fileName": "Future site of AmTrac memorial",
        "instructions": "Future site of the Amphibious Assault Memorial",
        "name": "Future site of AmTrac memorial",
        "sectionId": "1571154382901",
        "sectionName": "198"
    },
    {
        "fileName": "Houston and Myers Family Bench",
        "instructions": null,
        "name": "Houston and Myers Family Bench",
        "sectionId": "1572990310577",
        "sectionName": "44"
    },
    {
        "fileName": "Intersection_E_-_DDT_and_OKH_to_NMMC",
        "instructions": "At the intersection, turn right for the Devil Dog Trail.",
        "name": "Old King's Highway to Devil Dog Trail",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571153176167",
        "prevSectionId": "1571153186215"
    },
    {
        "fileName": "Intersection E - DDT to OKH",
        "instructions": "At the intersection, turn right on Old King's Highway to head back towards the Museum.",
        "name": "Devil Dog Trail to Old King's Highway",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571152965593",
        "prevSectionId": "1571153176167"
    },
    {
        "fileName": "3rdBn 3rdMarines",
        "instructions": "3rd Battalion, 3rd Marines is known as “America’s Battalion.” Formed in 1942, this infantry unit has seen action in World War II, Vietnam, The Gulf War and the Global War on Terror",
        "name": "3rd Battalion 3rd Marines ",
        "sectionId": "1571153676623",
        "sectionName": "178"
    },
    {
        "fileName": "Wisconsin Fallen Warrior",
        "instructions": "Dedicated to Wisconsin fallen Marines. This symbol for a fallen warrior goes back to World War I. It is a tribute to all American service members who made the ultimate sacrifice. The original monument was commissioned for placement at Navy and Marine Corps Reserve Center in Madison, WI.",
        "name": "Fallen Warrior",
        "sectionId": "1571151623228",
        "sectionName": "142"
    },
    {
        "fileName": "LtGen John A. Lejeune Bench",
        "instructions": null,
        "name": "LtGen John A. Lejeune Bench 1",
        "sectionId": "1571065956547",
        "sectionName": "129"
    },
    {
        "fileName": "Hotel 2ndBn 7thMar",
        "instructions": "Hotel Company, 2nd Battalion, 7th Marine Regiment is an infantry unit that served in Vietnam from July 1965 to September 1970, where they fought in more than 50 operations, including at Qui Nhon, Chu Lai, Dai Loc and An Hoa. 84 Marines were killed in action",
        "name": "Hotel 2ndBn 7thMarines",
        "sectionId": "1571153680814",
        "sectionName": "179"
    },
    {
        "fileName": "2ndLt Joseph F. Dryer Memorial Bench",
        "instructions": null,
        "name": "2ndLt Joseph F. Dryer Memorial Bench",
        "sectionId": "1571153627332",
        "sectionName": "170"
    },
    {
        "fileName": "Intersection E - DDT.OKH to NMMC",
        "instructions": "At the intersection, continue straight to stay on the Old King's Hwy Trail.",
        "name": "Remain on Old King's Highway",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571152965593",
        "prevSectionId": "1571153186215"
    },
    {
        "fileName": "LtGen John A. Lejeune Bench(2)",
        "instructions": null,
        "name": "LtGen John A. Lejeune Bench 2",
        "sectionId": "1571159274156",
        "sectionName": "208"
    },
    {
        "fileName": "Golf 2ndBn 7thMar",
        "instructions": "Golf Company, 2nd Battalion, 7th Marine Regiment, 1st Marine Division is an infantry unit that served in Vietnam from July 1965 to October 1970, where they fought at Qui Nhon, Chu Lai, Dai Loc and An Hoa",
        "name": "Golf 2ndBn 7thMarines",
        "sectionId": "1571153711796",
        "sectionName": "186"
    },
    {
        "fileName": "Intersection Q - chpl entr swtch to chpl trl",
        "instructions": "At the intersection, turn right onto the Chapel Trail in the direction of the  LtGen Lewis B. \"Chesty\" Puller statue and rally point.",
        "name": "Chapel plaza switchback to Chapel Trail",
        "sectionId": "1572990328401",
        "sectionName": "90",
        "isIntersection": true,
        "nextSectionId": "1572990304138",
        "prevSectionId": "1572990331984"
    },
    {
        "fileName": "Force Recon and Special Ops",
        "instructions": "Sgt Reckless carried ammunition to the front lines for the 75mm Recoilless Rifle Platoon of the 5th Marines. Perhaps her most valiant effort was in the Battle of Outpost Vegas in March 1953, in which she was wounded twice and carried over 9,000 pounds of ammunition. Reckless earned multiple medals and was eventually promoted to SSgt in 1959 by Gen Pate, 21st Commandant of the Marine Corps",
        "name": "Force Recon",
        "sectionId": "1571153186215",
        "sectionName": "158"
    },
    {
        "fileName": "TBS 3-67 and 41st OCC",
        "instructions": "Commissioned in 1966, the men of the 41st Officer Candidate Course went on together to Basic Officers Course. During the Vietnam War, 43 members of this class along with 2 instructors were killed in combat. Many received the Navy Cross, Silver Star, Bronze Star and Purple Heart.",
        "name": "TBS Class 3-67 and 41st OCC ",
        "sectionId": "1571153508575",
        "sectionName": "166"
    },
    {
        "fileName": "Brennan Family Bench",
        "instructions": null,
        "name": "Brennan Family Bench",
        "sectionId": "1571152615560",
        "sectionName": "147"
    },
    {
        "fileName": "2-4 Trail Marker",
        "instructions": "The bricks in this segment of trail represent a years-long research project to identify all of the Marines and Sailors of 2-4 who were killed in action.",
        "name": "2nd Battalion 4th Marines Trail",
        "sectionId": "1571153176167",
        "sectionName": "215"
    },
    {
        "fileName": "INtersection S - SFMP to parking\n\n",
        "instructions": "Exit Semper Fidelis Memorial Park at the parking lot",
        "name": "Exiting SFMP to Parking Lot",
        "sectionId": "1572991074657",
        "sectionName": "128",
        "isIntersection": true,
        "nextSectionId": "1572991074657",
        "prevSectionId": "1572991072914"
    },
    {
        "fileName": "Intersection G - DDT to Marine",
        "instructions": "At the intersection, turn right on Marine Trail to head in the direction of the Engineers Monument.",
        "name": "Devil Dog Trail to Marine Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571153799662",
        "prevSectionId": "1571159260752"
    },
    {
        "fileName": "Historic Old Kings Hwy",
        "instructions": "The gravel road follows the historic \"Potomac Path,\" later known as King's Highway, upon which George Washington and French troops travelled to Yorktown during the Revolutionary War.",
        "name": "Historic Old King's Hwy",
        "sectionId": "1571153470136",
        "sectionName": "163"
    },
    {
        "fileName": "Chapel Trail Seating",
        "instructions": null,
        "name": "Chapel Trail Seating",
        "sectionId": "1572988935014",
        "sectionName": "35"
    },
    {
        "fileName": "2ndBn 1stMar Vietnam",
        "instructions": "Though the true nickname of this infantry unit is “The Professionals,” it earned another in Vietnam in 1968. While the Marines were operating near Khe Sanh under cover of artillery fire, a North Vietnamese Army commander said 2/1 disappeared from his sector, thus coining the legendary nickname of “Ghost Battalion.”",
        "name": "2nd Battalion 1st Marines Vietnam",
        "sectionId": "1571153666548",
        "sectionName": "176"
    },
    {
        "fileName": "Seagoing Marines",
        "instructions": "Even though the word “Marine” is related to the sea, many people only associate Marines with land, air and amphibious activities. However, Marines first served in seagoing detachments on the USS Cabot in December 1775 and are still seagoing today onboard naval ships",
        "name": "Seagoing Marines ",
        "sectionId": "1572990304138",
        "sectionName": "42"
    },
    {
        "fileName": "Melody Dawn Carey Memorial Bench",
        "instructions": null,
        "name": "Melody Dawn Carey Memorial Bench",
        "sectionId": "1571161046821",
        "sectionName": "222"
    },
    {
        "fileName": "Intersection N - chapel trl to chapel OR puller",
        "instructions": "At the intersection, turn right to continue on the Chapel Trail towards the LtGen Lewis B. \"Chesty\" Puller statue and rally point.",
        "name": "Chapel entrance plaza to Puller overlook",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990304138",
        "prevSectionId": "1572990384808"
    },
    {
        "fileName": "Intersection R - chapel trail to puller",
        "instructions": "At the Chesty Puller statue and rally point, turn right onto the Chesty Puller Trail",
        "name": "Chapel Trail to Chesty Puller Trail",
        "sectionId": "1572990865849",
        "sectionName": "104",
        "isIntersection": true,
        "nextSectionId": "1572990869228",
        "prevSectionId": "1572990316105"
    },
    {
        "fileName": "BOC 1-69",
        "instructions": "The 250 lieutenants of TBS/ Basic Officers Course 1-69 Company A were sent to Vietnam directly upon graduation. Five of their classmates were killed in action, and one was declared missing in action",
        "name": "BOC 1-69 ",
        "sectionId": "1571161515283",
        "sectionName": "234"
    },
    {
        "fileName": "Intersection B - BdF and Footprints towards Lejeune\n\n",
        "instructions": "At the intersection between the Esprit de Corps Footprints and the Blvd de France trail, turn left to walk towards the Lejeune statue and Marine Trail.",
        "name": "Footprints to Blvd de France",
        "sectionId": "1572992488508",
        "sectionName": "L",
        "isIntersection": true,
        "nextSectionId": "1572992495117",
        "prevSectionId": "1572989734415"
    },
    {
        "fileName": "Intersection E - DDT to OKH",
        "instructions": "At the intersection, turn left on Old King's Highway to head out towards the Sgt Reckless Monument.",
        "name": "Devil Dog Trail to Old King's Highway",
        "sectionId": "1571152970943",
        "sectionName": "157",
        "isIntersection": true,
        "nextSectionId": "1571153186215",
        "prevSectionId": "1571153176167"
    },
    {
        "fileName": "Marine Corps Wives and Families Bench",
        "instructions": null,
        "name": "Marine Corps Wives and Families Bench",
        "sectionId": "1572990199384",
        "sectionName": "40"
    },
    {
        "fileName": "Intersection R - puller trail to chapel",
        "instructions": "At the Chesty Puller statue and rally point, turn left onto the Chapel Trail",
        "name": "Chesty Puller Trail to Chapel Trail",
        "sectionId": "1572990865849",
        "sectionName": "104",
        "isIntersection": true,
        "nextSectionId": "1572990316105",
        "prevSectionId": "1572990869228"
    },
    {
        "fileName": "FDNY 9.11",
        "instructions": "This monument honors the Marines who were killed in the 2001 attack on World Trade Center. Includes steel beams from one of the towers.",
        "name": "Fire Department New York 9/11",
        "sectionId": "1571153631062",
        "sectionName": "171"
    },
    {
        "fileName": "Intersection D - Marine to Old King's Hwy",
        "instructions": "At the intersection, go straight to follow the Old King's Hwy Trail.",
        "name": "Marine Trail to Old King's Hwy Trail",
        "sectionId": "1571152343583",
        "isIntersection": true,
        "sectionName": "141"
    },
    {
        "fileName": "Intersection K - Chapel to swtchbk to Taylor\n\n",
        "instructions": "At this intersection turn left towards the Chapel Trail switchbacks and down the hill.",
        "name": "Chapel Trail to Chapel Trail switchbacks",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1571161576258",
        "prevSectionId": "1572990172847"
    },
    {
        "fileName": "2nd AmTrac Battalion Bench",
        "instructions": null,
        "name": "2nd AmTrac Battalion Bench",
        "sectionId": "1571152959750",
        "sectionName": "155"
    },
    {
        "fileName": "291 Mustangs 5th SBC 1951",
        "instructions": "Known as the 291 Mustangs, these Marines were pulled from the enlisted ranks and earned commissions as second lieutenants for duty in the Korean War, in 1951",
        "name": "291 Mustangs 5th SBC 1951",
        "sectionId": "1571160480718",
        "sectionName": "202"
    },
    {
        "fileName": "Bar on the Beach 5th MarDiv",
        "instructions": "This depiction of a Marine storming the beach with a Browning Automatic Rifle (BAR) honors the members of the 5th Marine division, who landed and fought on Iwo Jima in February 1945. In one month of fighting, they sustained 1,098 killed in action and 2,974 wounded, the highest casualty rate among the Marine divisions in the battle",
        "name": "Bar on the Beach 5th MarDiv",
        "sectionId": "1571161554106",
        "sectionName": "239"
    },
    {
        "fileName": "Intersection K - Swtchbk to Chapel trail",
        "instructions": "At the intersection, turn right to join the Chapel Trail leading towards the Semper Fidelis Memorial Chapel ",
        "name": "Chapel Trail switchbacks to Chapel Trail",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1572990172847",
        "prevSectionId": "1571161576258"
    },
    {
        "fileName": "Intersection D - OKH to Marine\n\n",
        "instructions": "At the intersection, follow the fork to the right to follow the Marine Trail up the hill.",
        "name": "Old King's Hwy Trail to Marine Trail",
        "isIntersection": true,
        "sectionId": "1571152343583",
        "sectionName": "141"
    },
    {
        "fileName": "Intersection C - Marine to BdF",
        "instructions": "The Blvd de France begins at the LtGen John A. Lejeune rally point and curves back towards the fron of the Museum.",
        "name": "Start of Blvd de France",
        "sectionId": "1571065956547",
        "sectionName": "129",
        "isIntersection": true,
        "nextSectionId": "1572992504125",
        "prevSectionId": "1571065956547"
    },
    {
        "fileName": "Intersection A - SFMP entrance",
        "instructions": "Midway along the plaza, is the concrete archway marking the entrance to Semper Fidelis Memorial Park and the first segment of trail labeled the Boulevard de France.",
        "name": "Entrance to Semper Fidelis Memorial Park",
        "sectionId": "1572993296596",
        "sectionName": "A"
    },
    {
        "fileName": "Maj Douglas Zembiec Memorial Bench",
        "instructions": null,
        "name": "Maj Douglas Zembiec Memorial Bench",
        "sectionId": "1571153711796",
        "sectionName": "186"
    },
    {
        "fileName": "Intersection F - Marine to Historic to OKH",
        "instructions": "Continue along the paved trail to follow onto the Old King's Hwy Trail. The gravel road follows the historic \"Potomac Path,\" later known as King's Highway, upon which George Washington and French troops travelled to Yorktown during the Revolutionary War.",
        "name": "Marine Trail to Old King's Highway",
        "sectionId": "1571153470136",
        "sectionName": "163",
        "isIntersection": true,
        "nextSectionId": "1571153469238",
        "prevSectionId": "1571153501287"
    },
    {
        "fileName": "Intersection K - Chapel trl to swtchbks",
        "instructions": "At this intersection continue straight to remain on the Chapel Trail in the direction of the Chapel",
        "name": "Chapel Trail to SFMP Chapel",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1572990172847",
        "prevSectionId": "1572988954150"
    },
    {
        "fileName": "Echo 2ndBn 7thMar",
        "instructions": "Echo Company, 2nd Battalion, 7th Marines is an infantry unit that served in Vietnam from 1965-70, during which they fought at Qui Nhon, Chu Lai, Dai Loc and An Hoa",
        "name": "Echo 2ndBn 7thMarines",
        "sectionId": "1571161017541",
        "sectionName": "219"
    },
    {
        "fileName": "The Cusack and Schreib Bench",
        "instructions": null,
        "name": "The Cusack and Schreib Bench",
        "sectionId": "1571066159489",
        "sectionName": "135"
    },
    {
        "fileName": "Intersection Q - chpl entr swtch to chpl trl",
        "instructions": "At the intersection, turn left onto the Chapel Trail towards the Semper Fidelis Memorial Chapel",
        "name": "Chapel plaza switchback to Chapel Trail",
        "sectionId": "1572990328401",
        "sectionName": "90",
        "isIntersection": true,
        "nextSectionId": "1572990209754",
        "prevSectionId": "1572990331984"
    },
    {
        "fileName": "Semper Fidelis Memorial Chapel",
        "instructions": "The Semper Fidelis Memorial Chapel is a nondenominational chapel that serves as a contemplative space where Marines and all visitors can remember the service and sacrifices of those who have served our nation.",
        "name": "Semper Fidelis Memorial Chapel",
        "sectionId": "1572990384808",
        "sectionName": "100"
    },
    {
        "fileName": "Marine Corps Engineers",
        "instructions": "Since the establishment of the first Marine engineer organization, Company H, 1st Regiment, First Advance Base Brigade, in 1913, Marine engineers have served in the fields of combat engineering for more than 100 years",
        "name": "Marine Corps Engineers ",
        "sectionId": "1571153782548",
        "sectionName": "195"
    },
    {
        "fileName": "War Dog",
        "instructions": "This statue of Kurt, the first dog killed in action in Guam, represents the 25 dogs killed in action there and the hundreds of dogs that served in World War II as sentries, messengers and scouts, but were also dedicated and loyal companions to the Marines who served with them",
        "name": "War Dog",
        "sectionId": "1572988935014",
        "sectionName": "35"
    },
    {
        "fileName": "Intersection N -chapel entr or chapel trl",
        "instructions": "At the intersection, turn left towards the Semper Fidelis Memorial Chapel entrance plaza.",
        "name": "Chapel Trail to Chapel entrance plaza",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990384808",
        "prevSectionId": "1572990304138"
    },
    {
        "fileName": "Col Nick Pratt Memorial Bench",
        "instructions": null,
        "name": "Col Nick Pratt Memorial Bench",
        "sectionId": "1571153711796",
        "sectionName": "186"
    },
    {
        "fileName": "3rdBn 26thMarines",
        "instructions": "3rd Battalion 26th Marines first fought at Iwo Jima in World War II. This monument is in memory of those lost while serving in Vietnam from December 1966 to March 1970. 3/26 has a Medal of Honor recipient from both WWII and Vietnam and is one of the most highly decorated units to serve in Vietnam.",
        "name": "3rd Battalion 26th Marines ",
        "sectionId": "1571152611742",
        "sectionName": "146"
    },
    {
        "fileName": "LtGen John A. LeJeune",
        "instructions": "Known as “the greatest of all leathernecks,” Lieutenant General John A. Lejeune served in the Marine Corps for 40 years, beginning in 1890. He was the first commanding officer of Marine Barracks Quantico at the start of WWI and served as Commandant of the Marine Corps from 1920 to 1929. He was the recipient of several medals, including the Navy Distinguished Service Medal for his service in WWI.",
        "name": "Lieutenant General John A. Lejeune",
        "sectionId": "1571065956547",
        "sectionName": "129"
    },
    {
        "fileName": "2ndLt J. P. Blecksmith Memorial Bench",
        "instructions": null,
        "name": "2ndLt J. P. Blecksmith Memorial Bench",
        "sectionId": "1572988935014",
        "sectionName": "35"
    },
    {
        "fileName": "Dunk and Kryst Family Memorial Bench",
        "instructions": null,
        "name": "Dunk and Kryst Family Memorial Bench",
        "sectionId": "1572991056938",
        "sectionName": "122"
    },
    {
        "fileName": "Intersection K - Swtchbk to Chapel trail",
        "instructions": "At the intersection, turn left to join the Chapel Trail leading to the Taylor Overlook and Crucible switchbacks.",
        "name": "Chapel Trail Swithbacks to Chapel Trail",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1572988954150",
        "prevSectionId": "1571161576258"
    },
    {
        "fileName": "Co F 2d Bn 5th BOC Memorial Bench",
        "instructions": null,
        "name": "Co F 2d Bn 5th BOC Memorial Bench",
        "sectionId": "1571160480718",
        "sectionName": "202"
    },
    {
        "fileName": "Co E 2d Bn 5th BOC Memorial Bench",
        "instructions": null,
        "name": "Co E 2d Bn 5th BOC Memorial Bench",
        "sectionId": "1571160480718",
        "sectionName": "202"
    },
    {
        "fileName": "Intersection P - UPR chpl entr plaza to upr chpl entr swtchbk",
        "instructions": "On the other side of the entrance plaza for the chapel is the Chapel entrance switchback. There are engraved bricks located along this section of trail.",
        "name": "Intersection of Chapel plaza and Chapel Plaza switchback",
        "sectionId": "1572990384808",
        "sectionName": "100",
        "isIntersection": true,
        "nextSectionId": "1572990378754",
        "prevSectionId": "1572990209754"
    },
    {
        "fileName": "Iron Mike",
        "instructions": "The term “Iron Mike,” originating from WWI to honor those Marines who fought in the war, is military slang for an especially inspiring, brave and tough service member. A number of servicemen and women have dedicated statues to American fighting forces in the name of “Iron Mike.”",
        "name": "Iron Mike",
        "sectionId": "1572993725015",
        "sectionName": "81"
    },
    {
        "fileName": "Intersection N -chapel entr or chapel trl",
        "instructions": "At the intersection, turn right onto the Chapel Trail in the direction of the Museum.",
        "name": "Chapel Trail to Chapel Trail ",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990199384",
        "prevSectionId": "1572990304138"
    },
    {
        "fileName": "1st 155 Gun Battery of Vietnam Bench",
        "instructions": null,
        "name": "1st 155 Gun Battery of Vietnam Bench",
        "sectionId": "1571153508575",
        "sectionName": "166"
    },
    {
        "fileName": "Intersection L - N Crucible to Taylor\n\n",
        "instructions": "From the Taylor Overlook, follow the trail to your left to join the Chapel Trail",
        "name": "Crucible North switchbacks to Chapel Trail",
        "sectionId": "1572988962822",
        "sectionName": "31",
        "isIntersection": true,
        "nextSectionId": "1572988960230",
        "prevSectionId": "1572989183719"
    },
    {
        "fileName": "The Chosin Few",
        "instructions": "Dedicated to the United Nations Forces that participated in the Battle of Chosin Reservoir that helped determine the fate of the Republic of Korea. From 27 November to 10 December 1950, 18,000 UN Forces fought about 120,000 Chinese in four concentrated areas across 40 miles in the area of the reservoir",
        "name": "The Chosin Few",
        "sectionId": "1571161046821",
        "sectionName": "222"
    },
    {
        "fileName": "Esprit de Corps Footprints",
        "instructions": "The Esprit de Corps Footprints ",
        "name": "Esprit de Corps Footprints",
        "sectionId": "1572992488508",
        "sectionName": "L"
    },
    {
        "fileName": "Jackie Ruth Dyer Cusack Memorial Bench",
        "instructions": null,
        "name": "Jackie Ruth Dyer Cusack Memorial Bench",
        "sectionId": "1572991056938",
        "sectionName": "122"
    },
    {
        "fileName": "PFC Jim Petri Memorial Bench",
        "instructions": null,
        "name": "PFC Jim Petri Memorial Bench",
        "sectionId": "1571153186215",
        "sectionName": "158"
    },
    {
        "fileName": "John M. Haviland Memorial Bench",
        "instructions": null,
        "name": "John M. Haviland Memorial Bench",
        "sectionId": "1572993301950",
        "sectionName": "C"
    },
    {
        "fileName": "Intersection L - Taylor to S crucible",
        "instructions": "From the Taylor Overlook, follow the trail to your left to follow the South Crucible switchbacks down the hill.",
        "name": "Chapel Trail to Crucible South switchbacks",
        "sectionId": "1572988962822",
        "sectionName": "31",
        "isIntersection": true,
        "nextSectionId": "1572989063391",
        "prevSectionId": "1572988960230"
    },
    {
        "fileName": "WWII US Marine Raiders Memorial Bench",
        "instructions": null,
        "name": "WWII US Marine Raiders Memorial Bench",
        "sectionId": "1572990879233",
        "sectionName": "108"
    },
    {
        "fileName": "TBS 3-57",
        "instructions": "550 Marine officers graduated from The Basic School class 3-57 as part of the 3rd BOC at Camp Goettge, Guadalcanal Area. Many served in Vietnam",
        "name": "Basic School Class 3-57 ",
        "sectionId": "1571161478341",
        "sectionName": "227"
    },
    {
        "fileName": "1stBn 7thMarines",
        "instructions": "Dedicated to the Marines and Corpsmen of 1st Battalion 7th Marines that were Killed In Action in Vietnam, 1965–1970",
        "name": "1st Battalion 7th Marines",
        "sectionId": "1571152778254",
        "sectionName": "150"
    },
    {
        "fileName": "Intersection D - Day to marine or okh",
        "instructions": "At the intersection, take the fork to the right to remain on the Marine Trail in the direction of the Museum.",
        "name": "Continue on Marine Trail",
        "sectionId": "1571152343583",
        "sectionName": "141",
        "isIntersection": true,
        "nextSectionId": "1571152337376",
        "prevSectionId": "1571151646442"
    },
    {
        "fileName": "Capt Robert W. Falkenbach Jr Memorial Bench",
        "instructions": null,
        "name": "Capt Robert W. Falkenbach Jr Memorial Bench",
        "sectionId": "1572990185977",
        "sectionName": "38"
    },
    {
        "fileName": "Intersection K - Chapel trl to swtchbks",
        "instructions": "At this intersection turn right towards the Chapel Trail switchbacks and down the hill.",
        "name": "Chapel Trail to Chapel Trail switchbacks",
        "sectionId": "1572988935014",
        "sectionName": "35",
        "isIntersection": true,
        "nextSectionId": "1571161576258",
        "prevSectionId": "1572988954150"
    },
    {
        "fileName": "Intersection N - chapel trail to chapel entr plaza",
        "instructions": "At the intersection, turn left to continue on the Chapel Trail towards the LtGen Lewis B. \"Chesty\" Puller statue and rally point.",
        "name": "Chapel Trail to Chapel Trail",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990304138",
        "prevSectionId": "1572990199384"
    },
    {
        "fileName": "Patrick Taylor Overlook",
        "instructions": null,
        "name": "Patrick Taylor Overlook",
        "sectionId": "1572988962822",
        "sectionName": "31"
    },
    {
        "fileName": "Intersection D - Day to marine or okh",
        "instructions": "At the intersection, turn left to follow the Old King's Hwy Trail.",
        "name": "Marine Trail to Old King's Hwt Trail",
        "sectionId": "1571152343583",
        "sectionName": "141",
        "isIntersection": true,
        "nextSectionId": "1571151623228",
        "prevSectionId": "1571151646442"
    },
    {
        "fileName": "Intersection M - EspritdeCorps Footprints to Crucible",
        "instructions": "From the Esprit de Corps Footprints, take the trail to your right for the South Crucible switchbacks going up the hill to the Taylor Overlook and the Chapel Trail",
        "name": "Footprints to  South Crucible",
        "sectionId": "1572989734415",
        "sectionName": "2",
        "isIntersection": true,
        "nextSectionId": "1572989758439",
        "prevSectionId": "1572992488508"
    },
    {
        "fileName": "Pvt Homer Cutrer Memorial Bench",
        "instructions": null,
        "name": "Pvt Homer Cutrer Memorial Bench",
        "sectionId": "1571161046821",
        "sectionName": "222"
    },
    {
        "fileName": "Molly Marine ",
        "instructions": "This depiction of a WWII woman Marine is a recreation of an original 1943 statue, which was made to encourage women to join the Corps during WWII. Molly Marine has come to represent all women who have earned the distinguished title of Marine. Today, approximately 13,000 women serve in the Marine Corps",
        "name": "Molly Marine ",
        "sectionId": "1572991068075",
        "sectionName": "125"
    },
    {
        "fileName": "Intersection G - DDT at Marine to NMMC",
        "instructions": "At the intersection, turn left for the Devil Dog Trail.",
        "name": "Marine Trail to Devil Dog Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571159260752",
        "prevSectionId": "1571153799662"
    },
    {
        "fileName": "Intersection M - EspritdeCorps Footprints to Crucible",
        "instructions": "From the Esprit de Corps Footprints, take the trail to your left for the North Crucible switchbacks going up the hill to the Taylor Overlook and the Chapel Trail",
        "name": "Footprints to North Crucible",
        "sectionId": "1572989733095",
        "sectionName": "1",
        "isIntersection": true,
        "nextSectionId": "1572989730832",
        "prevSectionId": "1572992488508"
    },
    {
        "fileName": "Intersection D - OKH to Marine",
        "instructions": "At the intersection, go straight to follow the Marine Trail back towards the Museum.",
        "name": "Old King's Hwy Trail to Marine Trail",
        "isIntersection": true,
        "sectionId": "1571152343583",
        "sectionName": "141"
    },
    {
        "fileName": "Intersection G - DDT at Marine awayfrom NMMC",
        "instructions": "At the intersection, continue straight as you pass the Devil Dog Trail Memorial Wall to remain on the Marine Trail.",
        "name": "Remain on Marine Trail",
        "sectionId": "1571154376950",
        "sectionName": "197",
        "isIntersection": true,
        "nextSectionId": "1571153799662",
        "prevSectionId": "1571154382901"
    },
    {
        "fileName": "MCL Puller Sponsors trail",
        "instructions": "This section of trail within the Chapel Trail Switchbacks recognizes Marine Corps League sponsors of the Chesty Puller statue and rally point.",
        "name": "Trail segment for Marine Corps League Puller sponsors",
        "sectionId": "1571161576258",
        "sectionName": "245"
    },
    {
        "fileName": "Magnificent Bastards",
        "instructions": "Second Battalion, 4th Marines is an infantry unit that first activated in April 1914 during World War I. Since activation, 2/4 has played a role in every major American conflict to the present",
        "name": "Magnificent Bastards",
        "sectionId": "1572990307673",
        "sectionName": "43"
    },
    {
        "fileName": "Col George Messenger Foote Bench",
        "instructions": null,
        "name": "Col George Messenger Foote Bench",
        "sectionId": "1572990879233",
        "sectionName": "108"
    },
    {
        "fileName": "Denigs Demons",
        "instructions": "In 1941, Gen Robert Denig Sr. formed the first Marine Corps Public Relations Division. He recruited civilian photographers, news reporters and radio personalities. These correspondents, who became known as Denig’s Demons, covered World War II in the Pacific.",
        "name": "Denig’s Demons",
        "sectionId": "1572990879233",
        "sectionName": "108"
    },
    {
        "fileName": "Tronvold Foundation Bench",
        "instructions": null,
        "name": "Tronvold Foundation Bench",
        "sectionId": "1572993299798",
        "sectionName": "B"
    },
    {
        "fileName": "Intersection H - Day from Marine to Chapel",
        "instructions": "At the intersection, take the fork to the left to remain on the Marine Trail.",
        "name": "Marine Trail cross trail",
        "sectionId": "1571160488564",
        "sectionName": "216",
        "isIntersection": true,
        "nextSectionId": "1571160622332",
        "prevSectionId": "1571160480718"
    },
    {
        "fileName": "Intersection N - chapel trl to chapel OR puller",
        "instructions": "At the intersection, take the left fork to remain on the Chapel Trail towards the Museum",
        "name": "Chapel entrance plaza to Chapel Trail",
        "sectionId": "1572990209754",
        "sectionName": "41",
        "isIntersection": true,
        "nextSectionId": "1572990199384",
        "prevSectionId": "1572990384808"
    }
]
