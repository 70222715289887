import React from 'react';
import {Router} from "@reach/router";
import './App.scss';
import MenuScreen from "./ui/MenuScreen";
import BrickLocationScreen from "./ui/BrickLocationScreen";
import SectionLocationScreen from "./ui/SectionLocationScreen";
import LandmarkDirectoryScreen from "./ui/LandmarkDirectoryScreen";
import FindMyBrickScreen from "./ui/FindMyBrickScreen";
import {CloudinaryContext} from 'cloudinary-react';
function App() {
    return (
        <CloudinaryContext cloudName="merthin">
            <div className="App">
                <header>

                </header>
                <div className="Kernel">
                    <Router>
                        <MenuScreen path="/"/>
                        <BrickLocationScreen path="brick/:brickId/:originName"/>
                        <SectionLocationScreen path="section/:sectionName/:originName/:titleName"/>
                        <FindMyBrickScreen path="find-my-brick-search"/>
                        <FindMyBrickScreen path="donor-search"/>
                        <FindMyBrickScreen path="honoree-search"/>
                        <LandmarkDirectoryScreen path="landmark-directory"/>
                    </Router>
                </div>
                <div className="footer-space">
                </div>
            </div>
        </CloudinaryContext>
    );
}

export default App;
