import React, {Component} from 'react';
import "./LandmarkDirectoryScreen.scss";
import Footer from './Footer';
import _ from 'lodash';
import {navigate} from "@reach/router";
import {landmarks} from '../data/landmarks.js';
import {resolveLandmarkFilename, resolveLandmarkNameImage, resolveLandmarkThumbnail} from "./tools";
import {fill} from "@cloudinary/base/actions/resize";
import {Cloudinary} from "@cloudinary/base";

const landMarks = _.filter(landmarks, x => !x.isIntersection);

const cld = new Cloudinary({
    cloud: {
        cloudName: 'merthin'
    }
});

export default class LandmarkDirectoryScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSection: null,
            isShowingSelectedLandmark: false,
            selectedItem: null,
        };
    }

    renderSelectedLandmark() {
        const {selectedLandmark} = this.state;
        const imgFilename = resolveLandmarkThumbnail(selectedLandmark);
        const imageUrl = this.getImagePath(imgFilename);
        const titleClass = selectedLandmark.name.length < 40 ? "" : "compact"
        return <div className={"selectedLandmark"}>
            <h1 className={titleClass}>{selectedLandmark.name}</h1>

            <object data={imageUrl}
                    type="image/jpg">
                <img className="defaultImage" src={"/images/design/MHS-logo-white.png"}
                     width={350}
                     height={250}/>
            </object>}

            <p>{selectedLandmark.instructions}</p>
            <button onClick={() => this.setState({
                selectedSection: selectedLandmark,
            })}>
                Take me there
            </button>
            <button onClick={()=> {
                this.setState({
                    selectedLandmark: null,
                    isShowingSelectedLandmark: false,
                });
            }}>
                Go Back
            </button>
        </div>
    }

    renderPickOrigin() {
        const {selectedSection} = this.state;
        //TODO: Move to fetch this on app init.
        const origins = ["Museum","Museum Parking", "Chapel Parking"]
        return<div key="LandmarkDirectoryScreen" className="LandmarkDirectoryScreen">
            <h1>
                Landmark Directory
            </h1>
            <div key="area-list" className="selectionDialog">
                <h1>Where are you coming from?</h1>
                <div className="state-list">
                    {_.map(origins,x => {
                        return <div className="originButton" onClick={() => {
                            navigate(`section/${selectedSection.sectionName}/${x}/${selectedSection.name}`)
                        }}>
                            <h2>{x}</h2>
                        </div>
                    })}
                </div>
            </div>
            <Footer />
        </div>;
    };

    getImagePath = (name) => {
        const image = cld.image(name);
        image.resize(fill().width(350).height(250));
        return image.toURL();
    }

    render() {
        const {selectedSection, isShowingSelectedLandmark} = this.state;
        if(selectedSection) {
            return this.renderPickOrigin();
        }
        if(isShowingSelectedLandmark) {
            document.body.style.overflow='hidden';
        } else {
            document.body.style.overflow='auto';
        }
        return <div key="LandmarkDirectoryScreen" className="LandmarkDirectoryScreen">
            <h1>
                Landmark Directory
            </h1>
            <div className="landmark-list">
                {_.map(landMarks, (currentLandmark,i) => {
                    const imgFilename = resolveLandmarkThumbnail(currentLandmark);
                    const imageUrl = this.getImagePath(imgFilename);
                    return <div key={i} className="landmark" onClick={() => {
                        document.body.style.overflow='hidden';
                        this.setState({
                            isShowingSelectedLandmark: true,
                            selectedLandmark: currentLandmark
                        });
                    }}>
                        <object data={imageUrl}
                                type="image/jpg">
                            <img className="defaultImage" src={"/images/design/MHS-logo-white.png"}
                                 width={350}
                                 height={250}/>
                        </object>
                        <h1>{currentLandmark.name}</h1>
                    </div>
                })}
            </div>
            {isShowingSelectedLandmark && <div className={"selectedScreen"}></div> }
            {isShowingSelectedLandmark && this.renderSelectedLandmark()}
            <Footer />
        </div>
    }

    getDebugInfo = (landmark, imagePath) => {
        return <div className="debug" style={{fontSize:10}}>
            <strong>Intersection: {landmark.isIntersection ? "true" : "false"}</strong><br/>
            <strong>Name: {resolveLandmarkNameImage(landmark.name)}</strong><br/>
            <strong>Filename: {resolveLandmarkFilename(landmark.fileName)}</strong><br/>
            <strong>Cloudinary: {imagePath}</strong><br/>
        </div>
    }
}








