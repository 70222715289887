import React, {Component} from 'react';
import "./PaginationList.scss";
import _ from "lodash";

export default class PaginationList extends Component {
    constructor(props) {
        super(props);
        const finalItems = this.props.items || [];
        this.state = {
            items: finalItems,
            pageSize: this.props.cap,
            pageCount: finalItems.length / this.props.cap,
            currentPage: 0,
        }
    }

    renderHonoreeItem = (item, key) => {
        return <div className="honoree-item"
                    key={key}
                    onClick={() => {
                        if (this.props.click) {
                            this.props.click(item.id)
                        }
                    }}>
            {item.honoree ? item.honoree : item.donor}
        </div>
    }

    itemRender = (item, key) => {
        if(!this.props.renderMethod) {
            return this.renderHonoreeItem(item, key);
        }
        else {
            return this.props.renderMethod(item,key);
        }
    }

    render() {
        const {items, pageSize, currentPage, pageCount} = this.state;
        const visibleItems = [];
        for (let i = (currentPage * pageSize); i < Math.min((currentPage + 1) * pageSize, items.length); i++) {
            visibleItems.push(items[i]);
        }
        const hasNext = (currentPage + 1) < pageCount;
        const hasPrevious = currentPage >= 1;
        return <div key="PaginationList" className="PaginationList">
            <div className={this.props.classRoot ? this.props.classRoot : "honoree-list"}>
                {_.map(visibleItems, this.itemRender)}
            </div>
            {hasPrevious && <button className="buttonSmall" onClick={() => {
                let {currentPage} = this.state;
                currentPage--
                this.setState({currentPage});
            }}>
                Previous
            </button>}
            {hasNext && <button className="buttonSmall" onClick={() => {
                let {currentPage} = this.state;
                currentPage++;
                this.setState({currentPage});
            }}>
                Next
            </button>}
        </div>
    }
}








