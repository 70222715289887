import React, {Component} from 'react';
import './EagleIcon.scss';

export default class EagleIcon extends Component {
    render() {
        return <div className="EagleIcon" />
    }
}



